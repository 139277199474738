import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Resource.css';
import { fetchUserResource, getUserWaxBalance } from '../contractService'; // Bu fonksiyonun olduğu dosya yolu

function Resource({ wallet, selectedWallet }) {
  const [owner, setOwner] = useState(false);
  const [resourceData, setResourceData] = useState(null); // Gelen veriyi saklamak için state
  const [availableBalance, setAvailableBalance] = useState("Loading..."); // Kullanıcı bakiyesi

  const formatTime = (microseconds) => {
    const milliseconds = microseconds / 1000;
    const seconds = milliseconds / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
  
    if (hours >= 1) {
      return `${hours.toFixed(2)} h`;
    } else if (minutes >= 1) {
      return `${minutes.toFixed(2)} m`;
    } else if (seconds >= 1) {
      return `${seconds.toFixed(2)} s`;
    } else if (milliseconds >= 1) {
      return `${milliseconds.toFixed(2)} ms`;
    } else {
      return `${microseconds.toFixed(2)} μs`; // mikro saniye
    }
  };
  
  // Depolama birimini uygun şekilde dönüştürmek için fonksiyon (Byte, KB, MB, GB)
  const formatBytes = (bytes) => {
    const kb = bytes / 1024;
    const mb = kb / 1024;
    const gb = mb / 1024;
  
    if (gb >= 1) {
      return `${gb.toFixed(2)} GB`;
    } else if (mb >= 1) {
      return `${mb.toFixed(2)} MB`;
    } else if (kb >= 1) {
      return `${kb.toFixed(2)} KB`;
    } else {
      return `${bytes.toFixed(2)} Bytes`;
    }
  };

  useEffect(() => {
    if (wallet && wallet === selectedWallet) {
      setOwner(true);
    } else {
      setOwner(false);
    }
  }, [wallet, selectedWallet]);

  useEffect(() => {
    if (owner && wallet) {
      getUserWaxBalance(wallet)
        .then((balance) => {
          setAvailableBalance(balance); // Bakiyeyi state'e kaydet
        })
        .catch((error) => {
          console.error('Error fetching WAX balance:', error);
          setAvailableBalance("Error"); // Hata durumunda "Error" göster
        });
    }
  }, [owner, wallet]);

  // Sayfa açıldığında selectedWallet ile fetchUserResource çağrısı
  useEffect(() => {
    if (selectedWallet) {
      fetchUserResource(selectedWallet)
        .then((data) => {
          setResourceData(data); // Veriyi state'e kaydet
          console.log('User Resource Data:', data);
        })
        .catch((error) => {
          console.error('Error fetching user resource:', error);
        });
    }
  }, [selectedWallet]);

  // CPU, NET ve RAM yüzdelerini hesapla
  const cpuUtilization = resourceData
    ? (resourceData.cpu.used / resourceData.cpu.available) * 100
    : 0;

  const netUtilization = resourceData
    ? (resourceData.net.used / resourceData.net.available) * 100
    : 0;

  const ramUtilization = resourceData
    ? (resourceData.ram_usage / resourceData.ram_quota) * 100
    : 0;

  // CPU kullanımını uygun birimlerde gösterme
  const cpuDetails = resourceData
    ? `${formatTime(resourceData.cpu.used)} / ${formatTime(resourceData.cpu.available)}`
    : "Loading...";

  // NET kullanımını uygun Byte, KB, MB, GB birimlerinde gösterme
  const netDetails = resourceData
    ? `${formatBytes(resourceData.net.used)} / ${formatBytes(resourceData.net.available)}`
    : "Loading...";

  // RAM kullanımını uygun Byte, KB, MB, GB birimlerinde gösterme
  const ramDetails = resourceData
    ? `${formatBytes(resourceData.ram_usage)} / ${formatBytes(resourceData.ram_quota)}`
    : "Loading...";

  const handleManageResourcesClick = () => {
    window.open(`https://waxblock.io/account/${selectedWallet}`, '_blank', 'noopener noreferrer'); // Yeni sekmede link açar
  };

  return (
    <div className="resource">
      <h2>Resources</h2>
      <div className="resource-wallet-info">
        <p><strong>Wallet:</strong> {selectedWallet}</p>
      </div>
      <div className="resource-container">
        <div className="circular-progress-container">
          <CircularProgressbar
            value={ramUtilization}
            text={`${Math.round(ramUtilization)}%`}
            styles={buildStyles({
              pathColor: '#0fd4f0',
              textColor: '#fff',
              trailColor: '#333',
              backgroundColor: '#1c1c1c',
            })}
          />
          <p>RAM</p>
          <p className="details">{ramDetails}</p>
        </div>

        <div className="circular-progress-container">
          <CircularProgressbar
            value={cpuUtilization}
            text={`${Math.round(cpuUtilization)}%`}
            styles={buildStyles({
              pathColor: '#f4a261',
              textColor: '#fff',
              trailColor: '#333',
              backgroundColor: '#1c1c1c',
            })}
          />
          <p>CPU</p>
          <p className="details">{cpuDetails}</p>
        </div>

        <div className="circular-progress-container">
          <CircularProgressbar
            value={netUtilization}
            text={`${Math.round(netUtilization)}%`}
            styles={buildStyles({
              pathColor: '#ff4500',
              textColor: '#fff',
              trailColor: '#333',
              backgroundColor: '#1c1c1c',
            })}
          />
          <p>NET</p>
          <p className="details">{netDetails}</p>
        </div>
      </div>

     
     {owner && (
        <div className="stake-resource">
          <div className="balance-container">
            <p><strong>Available Balance: </strong>{availableBalance}</p>
          </div> 
          <div className="stake-button-container">
            <button onClick={handleManageResourcesClick}>
              Manage Resources
            </button>
          </div>
        </div>
      )}
    
    </div>
  );
}

export default Resource;
