import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Games from './pages/Games/Games';
import Marketplace from './pages/Marketplace/Marketplace';
import MyAccount from './pages/MyAccount';
import NotFound from './pages/NotFound/NotFound';
import ScrollToTop from './components/ScrollToTop';
import Theopenspace from './pages/Theopenspace/Main/Theopenspace';
import TheopenspaceGame from './pages/Theopenspace/Game/TheopenspaceGame';
import Explorermine from './pages/Explorermine/Main/Explorermine';
import ExplorermineGame from './pages/Explorermine/Game/ExplorermineGame';
import Health from './pages/Health/Health.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App d-flex flex-column min-vh-100">
        <Header />
        <main className="flex-grow-1">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/marketplace" element={<Marketplace />} />
            <Route path="/projects" element={<Games />} />
            <Route path="/myaccount" element={<MyAccount />} />
            <Route path="/theopenspace" element={<Theopenspace />} />
            <Route path="/theopenspace/play" element={<TheopenspaceGame />} />
            <Route path="/explorermine" element={<Explorermine />} />
            <Route path="/explorermine/play" element={<ExplorermineGame />} />
            <Route path="/health" element={<Health />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
