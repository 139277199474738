// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us-container {
  max-width: 1500px; /* Yan boşlukları daha da azaltmak için genişliği artırdık */
  margin: 50px auto; /* Üstten ve alttan 50px boşluk bırak */
  padding: 30px;
  background-color: #1e1e1e; /* Koyu gri arka plan */
  color: #ffffff; /* Beyaz yazı rengi */
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.about-us-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #00bcd4; /* Başlık rengi */
}

.about-us-description {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 30px;
  color: #dcdcdc; /* Açık gri yazı rengi */
}

.vision-section {
  margin-top: 40px;
}

.vision-title {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #00bcd4; /* Başlık rengi */
}

.vision-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #dcdcdc; /* Açık gri yazı rengi */
}

.about-us-footer {
  margin-top: 40px;
  border-top: 1px solid #333;
  padding-top: 20px;
}

.footer-text {
  font-size: 1.1rem;
  color: #999999; /* Orta gri yazı rengi */
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/About/About.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB,EAAE,4DAA4D;EAC/E,iBAAiB,EAAE,uCAAuC;EAC1D,aAAa;EACb,yBAAyB,EAAE,uBAAuB;EAClD,cAAc,EAAE,qBAAqB;EACrC,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,cAAc,EAAE,iBAAiB;AACnC;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc,EAAE,wBAAwB;AAC1C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,cAAc,EAAE,iBAAiB;AACnC;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,cAAc,EAAE,wBAAwB;AAC1C;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,cAAc,EAAE,wBAAwB;EACxC,kBAAkB;AACpB","sourcesContent":[".about-us-container {\n  max-width: 1500px; /* Yan boşlukları daha da azaltmak için genişliği artırdık */\n  margin: 50px auto; /* Üstten ve alttan 50px boşluk bırak */\n  padding: 30px;\n  background-color: #1e1e1e; /* Koyu gri arka plan */\n  color: #ffffff; /* Beyaz yazı rengi */\n  border-radius: 10px;\n  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);\n}\n\n.about-us-title {\n  font-size: 2rem;\n  margin-bottom: 20px;\n  color: #00bcd4; /* Başlık rengi */\n}\n\n.about-us-description {\n  font-size: 1.1rem;\n  line-height: 1.6;\n  margin-bottom: 30px;\n  color: #dcdcdc; /* Açık gri yazı rengi */\n}\n\n.vision-section {\n  margin-top: 40px;\n}\n\n.vision-title {\n  font-size: 2rem;\n  margin-bottom: 15px;\n  color: #00bcd4; /* Başlık rengi */\n}\n\n.vision-text {\n  font-size: 1.1rem;\n  line-height: 1.6;\n  color: #dcdcdc; /* Açık gri yazı rengi */\n}\n\n.about-us-footer {\n  margin-top: 40px;\n  border-top: 1px solid #333;\n  padding-top: 20px;\n}\n\n.footer-text {\n  font-size: 1.1rem;\n  color: #999999; /* Orta gri yazı rengi */\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
