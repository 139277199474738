import React from 'react';
import { Link } from 'react-router-dom'; // Eğer react-router-dom kullanıyorsan
import './Hero.css';

function HeroSection() {
  return (
    <section className="hero text-center">
      <div className="hero-content">
        <h1>Welcome to Ecosoft Games</h1>
        <p>Innovating the Future of Gaming with Cutting-Edge Technology</p>
        <div className="cta-buttons">
          <Link to="/games" className="btn btn-primary">Explore Our Games</Link>
          <a href="https://discord.gg/Qh5Axn3XFr" className="btn btn-secondary" target="_blank" rel="noopener noreferrer">
          Join Our Community <img src="https://cdn.icon-icons.com/icons2/2108/PNG/512/discord_icon_130958.png" alt="Discord" className="discord-logo" /> 
          </a>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
