import React, { useState, useEffect } from 'react';
import './WithdrawModal.css'; // Modal için CSS
import { getCurrentFee } from './contractService';

const WithdrawModal = ({ show, onClose, amount, tokenSymbol, precision, contract, onWithdrawConfirm }) => {
  // Başlangıç değerini token hassasiyetine göre ayarlıyoruz
  const getInitialWithdrawAmount = () => {
    if (precision > 0) {
      return `0.${'0'.repeat(precision)}`; // 0.00... (hassasiyet kadar sıfır)
    }
    return '0'; // Hassasiyet yoksa sadece 0
  };

  const [withdrawAmount, setWithdrawAmount] = useState(getInitialWithdrawAmount()); // Çekilecek miktarı kontrol ediyoruz
  const [fee, setFee] = useState(null); // Current fee durumu için state
  const [feeError, setFeeError] = useState(false); // Fee verisi çekilirken hata oluşursa
  const [netAmount, setNetAmount] = useState(amount); // Çekim sonrası net miktar

  // Modal açıldığında current fee verisini çekiyoruz ve amount'u sıfırlıyoruz
  useEffect(() => {
    if (show) {
      // Modal açıldığında amount miktarını sıfırlıyoruz
      setWithdrawAmount(getInitialWithdrawAmount());

      const fetchFee = async () => {
        try {
          const feeData = await getCurrentFee();
          if (feeData && feeData.fee) {
            setFee(feeData.fee); // Fee verisini kaydediyoruz
          } else {
            setFeeError(true); // Fee verisi yoksa hata durumuna geçiyoruz
          }
        } catch (error) {
          setFeeError(true); // Hata oluşursa durumu güncelliyoruz
          console.error('Error fetching fee:', error);
        }
      };

      fetchFee();
    }
  }, [show, precision]);

  // Net miktarı hesaplamak için kullanılan useEffect
  useEffect(() => {
    if (fee !== null) {
      const calculatedNetAmount = withdrawAmount - (withdrawAmount * fee / 100);
  
      // Net amount'ı yuvarlamadan sadece belirlenen hassasiyet kadar ondalık kısmı kesiyoruz
      const [integerPart, decimalPart] = calculatedNetAmount.toString().split('.');
  
      // Eğer ondalık kısım varsa ve tokenPrecision'dan uzun ise, bu kısmı tokenPrecision kadar kesiyoruz
      let formattedNetAmount;
      if (decimalPart) {
        formattedNetAmount = `${integerPart}.${decimalPart.substring(0, precision)}`;
      } else {
        formattedNetAmount = `${integerPart}.${'0'.repeat(precision)}`;
      }
  
      setNetAmount(formattedNetAmount); // Net miktarı güncellenmiş formatta kaydediyoruz
    }
  }, [withdrawAmount, fee, precision]);

  // Input değerini EOSIO token formatına uygun şekilde kontrol eden fonksiyon
  const handleWithdrawAmountChange = (e) => {
    let value = e.target.value;
  
    // Sadece sayılar ve bir adet nokta kabul edilir, ekstra karakterleri temizle
    value = value.replace(/[^0-9.]/g, '');
  
    // Nokta varsa ve ondalık kısım tokenPrecision'ı aşarsa kısalt
    if (value.includes('.')) {
      const [integerPart, decimalPart] = value.split('.');
      // Eğer ondalık kısım token hassasiyetini aşıyorsa kesiyoruz
      value = `${integerPart}.${decimalPart.substring(0, precision)}`;
    }
  
    // Eğer ondalık kısım yoksa ve hassasiyete eşleşmiyorsa sıfırları ekleyelim
    if (!value.includes('.') && precision > 0) {
      value = `${value}.${'0'.repeat(precision)}`;
    } else if (value.includes('.')) {
      const [integerPart, decimalPart] = value.split('.');
      if (decimalPart.length < precision) {
        value = `${integerPart}.${decimalPart}${'0'.repeat(precision - decimalPart.length)}`;
      }
    }
  
    // Girilen miktarın max değerden büyük olup olmadığını kontrol et
    if (parseFloat(value) > parseFloat(amount)) {
      value = parseFloat(amount).toFixed(precision); // Max değeri input'a yazdır
    }
  
    // Değeri güncelle
    setWithdrawAmount(value);
  };

  // Kullanıcı çekilecek maksimum token miktarını doldurmak için Max butonu
  const handleMaxClick = () => {
    // Eğer amount geçerli bir sayı değilse, herhangi bir işlem yapmadan geri dön
    if (isNaN(parseFloat(amount))) {
      console.error('Amount is not a valid number');
      return;
    }
  
    // Token hassasiyetine göre amount'ı string formatına getiriyoruz
    const [integerPart, decimalPart] = amount.split('.');
  
    // Eğer ondalık kısım varsa ve tokenPrecision ile uyumlu değilse ayarlıyoruz
    let formattedAmount;
    if (decimalPart) {
      formattedAmount = `${integerPart}.${decimalPart.substring(0, precision)}`;
    } else {
      formattedAmount = `${integerPart}.${'0'.repeat(precision)}`;
    }
  
    setWithdrawAmount(formattedAmount); // Değeri input'a yazdırıyoruz
  };

  // İşlem başlatma fonksiyonu
  const handleConfirm = async () => {
    console.log(withdrawAmount + " " + tokenSymbol)
    await onWithdrawConfirm(withdrawAmount + " " + tokenSymbol);
  };

  // Eğer gösterim kapalıysa null dön
  if (!show) return null;

  return (
    <div className="withdrawmodal-overlay">
      <div className="withdrawmodal-container">
        <div className="withdrawmodal-header">
          <h3>Withdraw</h3>
          <i className="fas fa-times close-icon" onClick={onClose}></i>
        </div>
        <div className="withdrawmodal-body">
            {feeError ? (
              <p>Unable to calculate the fee.</p>
            ) : fee !== null ? (
              <p><strong>Current Fee: </strong>{fee}%</p>
            ) : (
              <p>Loading fee information...</p>
            )}

            {/* Kullanıcı bakiyesini ve kontrat ismini gösteren alanlar */}
            <p><strong>Balance: </strong>{amount} {tokenSymbol}</p>
            <p><strong>Contract: </strong>{contract}</p>
            <div className="withdrawmodal-input-container">
              <input
                type="text"  // 'number' yerine 'text' kullanarak hassasiyetle tam kontrol sağlıyoruz
                id="withdraw-amount"
                value={withdrawAmount}
                onChange={handleWithdrawAmountChange} // Input değişikliği direkt state'e yazılıyor
                placeholder={`0.${'0'.repeat(precision)}`} // Örnek format
              />
              <button className="max-button" onClick={handleMaxClick}>Max</button> {/* Max butonu */}
            </div>

            <p><strong>Net Amount: </strong>{netAmount} {tokenSymbol}</p>
          </div>
        <div className="withdrawmodal-footer">
          <button
            className="confirm-button"
            onClick={handleConfirm}
            disabled={parseFloat(withdrawAmount) <= 0 || withdrawAmount > amount}>Withdraw</button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawModal;
