import React from 'react';

function MyAccount() {
  return (
    <div>
      <h1>My Account</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis maximus ornare imperdiet. Ut dolor nibh, rhoncus ac nisl eget, tempor gravida quam. In imperdiet fermentum luctus. Phasellus et imperdiet erat. Morbi euismod elit eget justo gravida tempor. Sed lectus diam, vulputate at rhoncus eget, fringilla eget nisi. In non ante dolor. Nullam sodales erat eget sapien mattis euismod. Donec accumsan elementum purus id porttitor. Nullam iaculis vel odio eu pellentesque. Nullam condimentum blandit commodo. Ut felis sem, congue vel ligula id, egestas semper quam.</p>
    </div>
  );
}

export default MyAccount;
