import { useEffect, useState } from 'react';
import React from 'react';
import './Dashboard.css';
import { fetchUserHistory, fetchTimers, fetchAuction, getUserBeforeBids } from '../contractService';
import BidModal from '../BidModal'; // BidModal bileşenini import edin

const Dashboard = ({ wallet, selectedWallet, userData, nftConfigs, poolConfigs, claimAct, registerAct, navigateToSection, refresh, BidAct, ClaimEscrowAct, ClaimAuctionAct }) => {
  const [claimableRewards, setClaimableRewards] = useState({});
  const [owner, setOwner] = useState(false);
  const [nextClaimTime, setNextClaimTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const [userAuctionBids, setUserAuctionBids] = useState([]); // Kullanıcının açık artırmalarını tutan state
  const [isBidModalOpen, setIsBidModalOpen] = useState(false); // BidModal açma durumu
  const [selectedAuction, setSelectedAuction] = useState(null); // Seçilen açık artırmayı kaydetmek için
  const [loading, setLoading] = useState(true); // Veriler yükleniyor mu kontrolü için

  useEffect(() => {
    if (wallet && wallet === selectedWallet) {
      setOwner(true);
    } else {
      setOwner(false);
    }
  }, [wallet, selectedWallet, userData]);

  useEffect(() => {
    if (userData && userData.weights && userData.weights.length > 0) {
      const calculateAndLogUserRates = async () => {
        try {
          const historyData = await fetchUserHistory(userData.lastclaim);
          let rewardMap = {}; // Her token birimi için ödülleri tutacak bir nesne
  
          historyData.forEach((historyItem) => {
            historyItem.weights.forEach((historyWeight) => {
              const userWeight = userData.weights.find(w => w.category === historyWeight.category)?.total_weight || 0;
              const poolWeight = historyWeight.total_weight;
              const userRate = (userWeight * 100) / poolWeight;
  
              const rewardString = poolConfigs.find(w => w.category === historyWeight.category)?.reward || '0.0000 STRDUST';
              const [rewardAmount, rewardToken] = rewardString.split(' '); // Token ve miktarı ayırıyoruz
  
              const precision = rewardAmount.split('.')[1]?.length || 4;
  
              rewardMap[rewardToken] = parseFloat(rewardMap[rewardToken] || 0) + parseFloat(rewardAmount) * (userRate / 100);
              rewardMap[rewardToken] = rewardMap[rewardToken].toFixed(precision); // Hassasiyete göre formatlama
            });
          });
  
          setClaimableRewards(rewardMap);
        } catch (error) {
          console.error("Error calculating and logging user rates:", error);
        }
      };
  
      calculateAndLogUserRates();
    } else {
      setClaimableRewards({}); // Weights yoksa claimable rewards'ı sıfırla
    }
  }, [userData, poolConfigs, refresh]);

  useEffect(() => {
    const fetchNextClaimTime = async () => {
      try {
        const timersData = await fetchTimers();
        if (timersData && timersData.cooldown) {
          const currentTime = Math.floor(Date.now() / 1000); // Şu anki zaman (Epoch)
          const timeUntilNextClaim = timersData.cooldown - currentTime; // Geriye kalan süre
  
          if (timeUntilNextClaim > 0) {
            setNextClaimTime(timersData.cooldown);
            setRemainingTime(timeUntilNextClaim);
          } else {
            refresh(); // Eğer süre dolmuşsa tabloyu yenile
          }
        }
      } catch (error) {
        console.error("Error fetching next claim time:", error);
      }
    };

    if (userData) {
      fetchNextClaimTime();
    }
  }, [userData, refresh, userData]);

  useEffect(() => {
    if (remainingTime > 0) {
      const interval = setInterval(() => {
        setRemainingTime(prevTime => prevTime - 1);
      }, 1000); // Her saniye geri sayımı güncelle

      return () => clearInterval(interval); // Temizleme
    }
  }, [remainingTime]);

  useEffect(() => {
    const fetchUserAuctions = async () => {
      setLoading(true);
      if (userData && userData.mybids && userData.mybids.length > 0) {
        const userAuctionBids = await Promise.all(
          userData.mybids.map(async (bidId) => {
            try {
              const auction = await fetchAuction(bidId) || { id: bidId, missing: true }; // Auction verisi eksikse bile boş bir kart oluştur
              const escrowData = await getUserBeforeBids(bidId, selectedWallet); // Escrow verilerini al
  
              // Ödenen token miktarını auction nesnesine ekle
              auction.token = escrowData ? escrowData.token : 'UNKNOW';
  
              return auction; // Auction'u escrow verisi ile döndür
            } catch (error) {
              // Hata durumunda boş bir auction döndür ve escrow verisini ekle
              const escrowData = await getUserBeforeBids(bidId, wallet);
              return {
                id: bidId,
                missing: true,
                token: escrowData ? escrowData.token : 'UNKNOW'
              };
            }
          })
        );
        setUserAuctionBids(userAuctionBids); // Sonuçları kaydet
      } else {
        setUserAuctionBids([]); // Eğer mybids yoksa boş array göster
      }
      setLoading(false);
    };
  
    fetchUserAuctions();
  }, [owner, refresh, userData, selectedWallet]);

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h}h ${m}m ${s}s`;
  };

  const getTotalClaimableAmount = () => {
    return Object.values(claimableRewards).reduce((sum, value) => sum + parseFloat(value), 0).toFixed(4);
  };

  const handleClaim = () => {
    console.log(`Claiming rewards for wallet: ${userData.wallet}`);
    claimAct(); 
  };

  const handleRegister = () => {
    console.log(`Register for wallet: ${wallet}`);
    registerAct();
  };

  const handleBidClick = (auction) => {
    setSelectedAuction(auction); // Seçilen açık artırmayı kaydet
    setIsBidModalOpen(true); // BidModal'ı aç
  };

  const handleClaimReward = async(id) => {
    console.log(`Claim reward for auction id: ${id}`);
    await ClaimAuctionAct(id)
  };

  const handleDrawTokens = async(id) => {
    console.log(`Draw tokens for auction id: ${id}`);
    await ClaimEscrowAct(id)
  };

  const handleConfirmBid = async(amount, id) => {    
    await BidAct(amount, id)
    setIsBidModalOpen(false);
  };

  const convertEpochToLocaleUTCString = (epochTime) => {
    const date = new Date(epochTime * 1000);
    const formattedDate = date.toLocaleDateString(undefined, {
      timeZone: 'UTC',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    const formattedTime = date.toLocaleTimeString(undefined, { timeZone: 'UTC' });
    return `${formattedDate} ${formattedTime}`;
  };

  const getPoolWeight = (categoryId) => {
    if (!poolConfigs) return 'N/A';
    const pool = poolConfigs.find(pool => pool.category === categoryId);
    return pool ? pool.pool_weight : 'N/A';
  };

  const renderClaimableRewards = () => {
    if (!userData || userData.weights.length === 0) {
      return <p><strong>Claimable Reward:</strong> None</p>;
    }

    const hasClaimableRewards = Object.values(claimableRewards).some(value => parseFloat(value) > 0);

    if (!hasClaimableRewards) {
      return <p><strong>Claimable Reward:</strong> Not Available</p>;
    }

    return (
      <p><strong>Claimable Reward:</strong> {Object.entries(claimableRewards).map(([token, amount]) => (
        <span key={token}>{amount} {token} </span>
      ))}</p>
    );
  };

  const renderUserAuctionBids = () => {

    if (loading) {
      return <p>Loading...</p>; // Veriler yükleniyorsa "Loading..." göster
    }

    if (!userAuctionBids || userAuctionBids.length === 0) {
      return <p>No bids found.</p>;
    }
  
    return (
      <div className="dashboard-auction-grid">
        {userAuctionBids.map((auction, index) => {
          const isMissing = auction.missing; // Auction bulunmamış
          const isEnded = auction.finish_time < Math.floor(Date.now() / 1000);
          const isLastBidder = auction.last_bidder === wallet;
          const isInLastBidders = auction.last_bidders && auction.last_bidders.includes(wallet);
  
          // Auction missing or ended
          if (isMissing || isEnded) {
            const message = isLastBidder
              ? "Won the auction"
              : "Lost the auction";
            const statusClass = isLastBidder ? 'auction-won' : 'auction-lost'; // CSS sınıfını belirle
  
            return (
              <div key={index} className="dashboard-auction-card">
                <p><strong>Auction ID:</strong> {auction.id}</p>
                <p className={`auction-status ${statusClass}`}>
                  {message}
                </p>
  
                {/* Eğer kaybettiyse ödenen miktarı göster */}
                {!isLastBidder && (
                  <p><strong>Paid:</strong> {auction.token}</p> // Escrow'daki ödenen miktar
                )}
  
                {/* Eğer kazandıysa kazandığı NFT'yi göster */}
                {isLastBidder && (
                  <p><strong>Reward NFT:</strong> #{auction.template_id}</p> // Kazanılan NFT template ID
                )}
  
                <div className="auction-buttons-container">
                    {owner && (isLastBidder ? (
                        <button onClick={() => handleClaimReward(auction.id)}>Claim Reward</button>
                    ) : (
                        <button onClick={() => handleDrawTokens(auction.id)}>Draw Tokens</button>
                    ))}
                </div>
              </div>
            );
          }
  
          // For extended auctions: if wallet is not in last_bidders, treat it as a lost auction
          if (auction.extra_time && !isInLastBidders) {
            const statusClass = 'auction-lost';
            return (
              <div key={index} className="dashboard-auction-card">
                <p><strong>Auction ID:</strong> {auction.id}</p>
                <p className={`auction-status ${statusClass}`}>
                  Lost the auction
                </p>
  
                {/* Eğer kaybettiyse ödenen miktarı göster */}
                <p><strong>Paid:</strong> {auction.token}</p> // Escrow'daki ödenen miktar
  
                {owner && (
                  <button onClick={() => handleDrawTokens(auction.id)}>Draw Tokens</button>
                )}
              </div>
            );
          }
  
          // For active auctions (not ended or missing)
          const endTime = auction.finish_time;
          const currentTime = Math.floor(Date.now() / 1000);
          let statusText = '';
          let statusClass = '';
          let remainingTime = '';
  
          if (currentTime < auction.start_time) {
            statusText = 'Waiting';
            statusClass = 'auction-waiting';
          } else if (currentTime < endTime) {
            statusText = 'Live';
            statusClass = 'auction-live';
            remainingTime = formatTime(endTime - currentTime);
          } else if (currentTime < endTime + auction.extra_time) {
            statusText = 'Extended';
            statusClass = 'auction-extended';
            remainingTime = formatTime(endTime + auction.extra_time - currentTime);
          } else {
            statusText = 'Ended';
            statusClass = 'auction-ended';
          }
  
          return (
            <div key={index} className="dashboard-auction-card">
              <p><strong>ID: </strong>{auction.id}</p>              
              <div className="dahsboard-auction-info">
                {(statusText === 'Live' || statusText === 'Extended') && remainingTime && (
                  <p><strong>Remaining: </strong>{remainingTime}</p>
                )}
                <p><strong>Price:</strong> {auction.price}</p>
                <p><strong>Last Bidder:</strong> {auction.last_bidder || 'None'}</p>
              </div>
              <div className={`dahsboard-auction-status ${statusClass}`}>
                {statusText}
              </div>
  
              {/* Owner ise butonları göster */}
              {owner && (statusText === 'Live' || statusText === 'Extended') && (
                <button onClick={() => handleBidClick(auction)}>Bid</button>
              )}
  
              {owner && statusText === 'Extended' && isInLastBidders && (
                <button onClick={() => handleBidClick(auction)}>Bid</button>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="dashboard">
      <h2>Dashboard</h2>
      {userData ? (
        <div className="user-data">
          <p><strong>Wallet:</strong> {userData.wallet}</p>
          <p><strong>Balance:</strong> {userData.balance.length > 0 ? userData.balance.join(', ') : 'No tokens available'}</p>
          <p><strong>Last Claim:</strong> {convertEpochToLocaleUTCString(userData.lastclaim)}</p>
          <p><strong>Next Claim Time:</strong> {nextClaimTime ? (remainingTime > 0 ? formatTime(remainingTime) : "Claim available!") : "Not available"}</p>
          
          <div className="claimable-reward">
            {renderClaimableRewards()}
            {owner && getTotalClaimableAmount() > 0 ? (
              <button className="claim-button active" onClick={handleClaim}>Claim</button>
            ) : (
              owner ? (
                <button className="claim-button inactive" disabled>Claim</button>
              ) : null
            )}
          </div>        

          <p><strong>Staked Category:</strong></p>
          {userData.weights.length > 0 ? (
            <div className="category-cards">
              {userData.weights.map((weight, index) => {
                const poolWeight = getPoolWeight(weight.category);
                const rate = ((weight.total_weight * 100) / poolWeight).toFixed(2);
                return (
                  <div key={index} className="category-card">
                    <div className="category-badge">Category { weight.category}</div>                      
                    <div className="category-info">
                      <p><strong>User Weight:</strong> {weight.total_weight}</p>
                      <p><strong>Pool Weight:</strong> {poolWeight}</p>
                      <p><strong>Rate:</strong> %{rate}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="no-stake">
              <p>No staked NFTs</p>
              {owner && (
                <button className="claim-button active" onClick={() => navigateToSection('inventory')}>Go to Inventory</button>
              )}
            </div>
          )}

          <p><strong>My Bids:</strong> {Array.isArray(userData.mybids) && userData.mybids.length > 0 ? '': 'No bids available'}</p>
          <div className="auction-section">
            {renderUserAuctionBids()}
          </div>          
        </div>
      ) : owner ? (
        <div className="register-section">
          <p>No user data found. Register to create a new account.</p>
          <button className="claim-button active" onClick={handleRegister}>Register</button>
        </div>      
      ) : (
        <p className="no-data">No user data loaded.</p>
      )}

      {/* BidModal */}
      {isBidModalOpen && selectedAuction && (
        <BidModal
          show={isBidModalOpen}
          onClose={() => setIsBidModalOpen(false)}
          wallet={wallet}
          auctionData={selectedAuction}
          remainingTime={formatTime(selectedAuction.finish_time - Math.floor(Date.now() / 1000))}
          handleConfirmBid={handleConfirmBid}
          userData={userData}
        />
      )}
    </div>
  );
};

export default Dashboard;
