// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/backgrounds/hero_bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
  color: white;
  padding: 150px 20px;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.cta-buttons button, .cta-buttons a {
  margin: 10px;
  padding: 15px 30px;
  font-size: 1.2em;
  display: inline-flex;
  align-items: center;
}

.cta-buttons .btn-primary {
  background-color: #00bcd4;
  border: none;
}

.cta-buttons .btn-secondary {
  background-color: #343a40;
  border: none;
}

.cta-buttons button:hover, .cta-buttons a:hover {
  background-color: #80deea;
  color: #fff;
}

.discord-logo {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Hero/Hero.css"],"names":[],"mappings":"AAAA;EACE,yDAA6D;EAC7D,sBAAsB;EACtB,2BAA2B;EAC3B,YAAY;EACZ,mBAAmB;EACnB,4CAA4C;AAC9C;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".hero {\n  background-image: url('../../assets/backgrounds/hero_bg.png');\n  background-size: cover;\n  background-position: center;\n  color: white;\n  padding: 150px 20px;\n  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);\n}\n\n.hero-content {\n  max-width: 800px;\n  margin: 0 auto;\n}\n\n.cta-buttons button, .cta-buttons a {\n  margin: 10px;\n  padding: 15px 30px;\n  font-size: 1.2em;\n  display: inline-flex;\n  align-items: center;\n}\n\n.cta-buttons .btn-primary {\n  background-color: #00bcd4;\n  border: none;\n}\n\n.cta-buttons .btn-secondary {\n  background-color: #343a40;\n  border: none;\n}\n\n.cta-buttons button:hover, .cta-buttons a:hover {\n  background-color: #80deea;\n  color: #fff;\n}\n\n.discord-logo {\n  width: 30px;\n  height: 30px;\n  margin-left: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
