import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import './Footer.css';

function Footer() {
  const [loading, setLoading] = useState(false); 
  const [submitted, setSubmitted] = useState(false); 

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    setTimeout(() => {
      setLoading(false); 
      setSubmitted(true); 
    }, 1000); 
  };

  return (
    <footer className="footer bg-dark text-white text-center py-3 mt-auto">
      <div className="container">
        <div className="about">
          <p>
            Ecosoft Games is a leading studio in developing sci-fi themed metaverse games, aiming to provide players with unique and immersive experiences.
          </p>
        </div>
        
        <div className="footer-nav">
          <Link to="/home">Home</Link> | 
          <Link to="/about"> About</Link> | 
          <Link to="/marketplace"> Marketplace</Link> | 
          <Link to="/projects"> Projects</Link>
        </div>

        <div className="social-icons">
          <a href="https://discord.gg/Qh5Axn3XFr" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-discord"></i>
          </a>
          <a href="https://t.me/theopenspaceio" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-telegram-plane"></i>
          </a>
          <a href="https://medium.com/@theopenspaceonline" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-medium-m"></i>
          </a>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://x.com/theopenspaceWAX" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
        </div>

        <div className="subscription-form">
          <p>Stay updated with the latest news and updates!</p>
          {!submitted ? (
            <form onSubmit={handleSubmit}>
              <input type="email" placeholder="Enter your email" required />
              <button type="submit" disabled={loading}>
                {loading ? <div className="spinner"></div> : "Subscribe"}
              </button>
            </form>
          ) : (
            <p className="thank-you-message">Thank you for subscribing!</p>
          )}
        </div>

        <p>&copy; 2024 Ecosoft Games. All rights reserved.</p>
        
        {/* Contact Information */}
        <p><strong>Contact:</strong> <a href="mailto:ecosofthub@gmail.com">ecosofthub@gmail.com</a></p>
        
        {/* Address Section */}
        <p><strong>Address:</strong> Türkiye / Balıkesir</p>
      </div>
    </footer>
  );
}

export default Footer;
