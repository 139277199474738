import React from 'react';
import { Link } from 'react-router-dom';
import './QuickAboutUs.css';

function QuickAboutUsSection() {
  return (
    <section className="quick-about-us text-center">
      <div className="overlay">
        <div className="content">
          <h2>Who We Are?</h2>
          <p>At Ecosoft Games, we create cutting-edge metaverse experiences that blend blockchain technology with immersive gameplay.</p>
          <Link to="/about" className="games-btn-secondary">Learn More</Link>
        </div>
      </div>
    </section>
  );
}

export default QuickAboutUsSection;
