import React from 'react';
import HeroSection from '../../components/Hero/Hero';
import QuickAboutUsSection from '../../components/QuickAboutUs/QuickAboutUs';
import GamesSection from '../../components/Games/Games';
import FeaturesSection from '../../components/Features/Features';
import PartnersSection from '../../components/SupportedPlatforms/SupportedPlatforms';
import './Home.css'; // CSS dosyasını dahil ediyoruz

function Home() {
  return (
    <div>
      <div className="home-section">
        <HeroSection />
      </div>
      <div className="home-section">
        <QuickAboutUsSection />
      </div>
      <div className="home-section">
        <GamesSection />
      </div>
      <div className="home-section">
        <FeaturesSection />
      </div>
      <div className="home-section">
        <PartnersSection />
      </div>
    </div>
  );
}

export default Home;
