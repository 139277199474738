import React, { useState, useEffect, useCallback } from 'react';
import AnchorLink from "anchor-link";
import AnchorLinkBrowserTransport from "anchor-link-browser-transport";
import * as waxjs from "@waxio/waxjs/dist";
import mycloud_w from '../../../assets/wallets/mycloud_wallet.png';
import anchor_w from '../../../assets/wallets/anchor_wallet.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ExplorermineGame.css';
import './Modal.css';
import { fetchUserData, fetchNftConfigs, fetchPools} from './contractService';

// Sayfa bileşenlerini import ediyoruz
import Dashboard from './pages/Dashboard';
import Inventory from './pages/Inventory';
import Wallet from './pages/Wallet';
import Pools from './pages/Pools';
import Auctions from './pages/Auctions';
import Resource from './pages/Resource';

const apiEndpoints = ["https://wax.greymass.com"];
const testapiEndpoints = ["https://waxtestnet.greymass.com"];
const main_account = "explorermine"; // explorermine ile güncellenecek
const mainnet_address = "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4";
const testnet_address = "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12";

const transport = new AnchorLinkBrowserTransport();
const link = new AnchorLink({
  transport,
  chains: [
    {
      chainId: mainnet_address,
      nodeUrl: getRandomApiEndpoint(),
    },
    {
      chainId: testnet_address,
      nodeUrl: getRandomTestApiEndpoint(),
    },
  ],
});

function getRandomApiEndpoint() {
  const randomIndex = Math.floor(Math.random() * apiEndpoints.length);
  return apiEndpoints[randomIndex];
}

function getRandomTestApiEndpoint() {
  const randomTestIndex = Math.floor(Math.random() * testapiEndpoints.length);
  return testapiEndpoints[randomTestIndex];
}
function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

let wax = new waxjs.WaxJS({
  rpcEndpoint: getRandomTestApiEndpoint(), //getRandomApiEndpoint(),
  tryAutoLogin: false
});


let walletAddress = null;
let identity = null;
let currentLoginType = null;


function ExplorerminePlay() {
  const [wallet, setWallet] = useState(null);
  const [logintype, setLoginType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState('dashboard');
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [userData, setUserData] = useState(null);
  const [nftConfigs, setNftConfigs] = useState(null);
  const [poolConfigs, setPoolConfigs] = useState(null);
  const [refresh, setRefresh] = useState(null);

  const fetchAndSetUserData = useCallback(async () => {
    if (selectedWallet) {
      const data = await fetchUserData(selectedWallet);
      setUserData(data);
      console.log(data);
    }
  }, [selectedWallet, refresh]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const savedWallet = getCookie('walletAddress');
    const savedLoginType = getCookie('logintype');
    const url = new URL(window.location);
    const urlWallet = url.searchParams.get('address');
    if (urlWallet) {
      setSelectedWallet(urlWallet);
    }

    if (savedWallet && savedLoginType) {
      setWallet(savedWallet);
      setLoginType(savedLoginType);
      if (!urlWallet) {
        url.searchParams.set('address', savedWallet);
        window.history.pushState({}, '', url);
      }
    }
  }, []);

  useEffect(() => {
    fetchAndSetUserData();
  }, [selectedWallet, fetchAndSetUserData]);

  useEffect(() => {
    const fetchConfigs = async () => {
      const configs = await fetchNftConfigs();
      setNftConfigs(configs); // Veriyi state'e kaydet
      console.log(configs);
    };

    fetchConfigs();
  }, []); // Boş bağımlılık dizisi ile sadece ilk yüklemede çalışır

  useEffect(() => {
    const fetchPools_ = async () => {
      const configs = await fetchPools();
      setPoolConfigs(configs); // Veriyi state'e kaydet
      console.log(configs);
    };

    fetchPools_();
  }, []); // Boş bağımlılık dizisi ile sadece ilk yüklemede çalışır

  useEffect(() => {
    // Hash değişimlerini izleyip sayfayı güncelle
    const handleHashChange = () => {
      const hash = window.location.hash.replace('#', '');
      if (hash) {
        setCurrentPage(hash);
      } else {
        setCurrentPage('dashboard');  // Varsayılan sayfa 'dashboard'
        window.location.hash = 'dashboard';
      }
    };
  
    window.addEventListener('hashchange', handleHashChange);
  
    // İlk açıldığında hash'i kontrol et
    handleHashChange();
  
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);
  

  const handleLogin = async (walletType_) => {
    if (walletType_ === 'cloudwallet') {
      try {
        await wax.login();
        walletAddress = wax.userAccount;
        setWallet(wax.userAccount);
        currentLoginType = '1';
      } catch (error) {
        console.error('Cloud Wallet login failed', error);
      }
    } else if (walletType_ === 'anchor') {
      try {
        identity = await link.login("explorermine");
        let { session } = identity;
        const toSimpleObject = (v) => JSON.parse(JSON.stringify(v));
        walletAddress = toSimpleObject(session.auth.actor);
        setWallet(toSimpleObject(session.auth.actor));
        
        if(toSimpleObject(session.chainId.chainName) === 10){
          currentLoginType = '2'; //mainnet
        }
        else{
          currentLoginType = '3'; //testnet
        }        
      } catch (error) {
        console.error('Anchor Wallet login failed', error);
      }
    }

    if (walletAddress) {
      setLoginType(currentLoginType);
      setCookie('walletAddress', walletAddress, 7);
      setCookie('logintype', currentLoginType, 7);
      const url = new URL(window.location);
      url.searchParams.set('address', walletAddress);
      window.history.pushState({}, '', url);
      setSelectedWallet(walletAddress);
      const data = await fetchUserData(walletAddress);
      setUserData(data);
      console.log(data)
    }

    setShowModal(false);
  };
 
  const handleLogout = async() => {
    setWallet(null);
    setLoginType(null);
    deleteCookie('walletAddress');
    deleteCookie('logintype');
    
    if(logintype === 1){
      await wax.logout();
    }
    else if(logintype === 2 || logintype === 3)
    {
      await link.clearSessions("explorermine");
      
    }
    
  };

  const navigateToSection = (section) => {
    window.location.hash = section;  // URL hash kısmını günceller
    setCurrentPage(section);         // State'i günceller
  };

  const updateAddressInURL = () => {
    const url = new URL(window.location);
    url.searchParams.set('address', wallet);
    window.history.pushState({}, '', url);
    
    setSelectedWallet(wallet); // selectedWallet'ı güncelle
  };

  async function claimAct() {
    try {
      let result;
      if (logintype === '1') {
        let actions = [
          {
            account: main_account,
            name: "claim",
            authorization: [
              {
                actor: wallet,
                permission: "active",
              },
            ],
            data: {
              wallet: wallet,
            },
          },
        ];
        result = await wax.api.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      } else if(logintype === '2' || logintype === '3') {
        
        let session = await link.restoreSession("explorermine");
        let actions = [
          {
            account: main_account,
            name: "claim",
            authorization: [
              {
                actor: wallet,
                permission: session.auth.permission,
              },
            ],
            data: {
              wallet: wallet,
            },
          },
        ];
        result = await session.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      }
      else
      {
        console.log("login type not found!")
        return;
      }
      console.log(result);
      
      await delay(2000);
      setRefresh(Date.now())
      
    } catch (err) {
      console.log(err);
    }
  }
  
  async function registerAct() {
    try {      
      let result;
      if (logintype === '1') {
        let actions = [
          {
            account: main_account,
            name: "newuser",
            authorization: [
              {
                actor: wallet,
                permission: "active",
              },
            ],
            data: {
              wallet: wallet,
            },
          },
        ];
        result = await wax.api.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      } else if(logintype === '2' || logintype === '3') {
        
        let session = await link.restoreSession("explorermine");
        let actions = [
          {
            account: main_account,
            name: "newuser",
            authorization: [
              {
                actor: wallet,
                permission: session.auth.permission,
              },
            ],
            data: {
              wallet: wallet,
            },
          },
        ];
        result = await session.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      }
      else
      {
        console.log("login type not found!")
        return;
      }
      console.log(result);
      
      await delay(2000);
      setRefresh(Date.now())
      
    } catch (err) {
      console.log(err);
    }
  }

  async function unstakeAct(assets) {    
    try {
      let result;
      if (logintype === '1') {
        let actions = [
          {
            account: main_account,
            name: "unstake",
            authorization: [
              {
                actor: wallet,
                permission: "active",
              },
            ],
            data: {
              wallet: wallet,
              asset_ids: assets,
            },
          },
        ];
        result = await wax.api.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      } else if(logintype === '2' || logintype === '3') {
        
        let session = await link.restoreSession("explorermine");
        let actions = [
          {
            account: main_account,
            name: "unstake",
            authorization: [
              {
                actor: wallet,
                permission: session.auth.permission,
              },
            ],
            data: {
              wallet: wallet,
              asset_ids: assets,
            },
          },
        ];
        result = await session.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      }
      else
      {
        console.log("login type not found!")
        return;
      }
      console.log(result);
      
      await delay(2000);
      setRefresh(Date.now())
    } catch (err) {
      console.log(err);
    }
  }

  async function stakeAct(assets) {
    
    try {
      let result;
      if (logintype === '1') {
        let actions = [
          {
            account: "atomicassets",
            name: "transfer",
            authorization: [
              {
                actor: wallet,
                permission: "active",
              },
            ],
            data: {
              from: wallet,
              to: "explorermine",
              asset_ids: assets,
              memo: "explorermine:\"stake\"",
            },
          },
          {
            account: main_account,
            name: "completenft",
            authorization: [
              {
                actor: wallet,
                permission: "active",
              },
            ],
            data: {
              wallet: wallet,
            },
          },
        ];
        result = await wax.api.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      } else if(logintype === '2' || logintype === '3') {
        
        let session = await link.restoreSession("explorermine");
        let actions = [
          {
            account: "atomicassets",
            name: "transfer",
            authorization: [
              {
                actor: wallet,
                permission: session.auth.permission,
              },
            ],
            data: {
              from: wallet,
              to: "explorermine",
              asset_ids: assets,
              memo: "explorermine:\"stake\"",
            },
          },
          {
            account: main_account,
            name: "completenft",
            authorization: [
              {
                actor: wallet,
                permission: session.auth.permission,
              },
            ],
            data: {
              wallet: wallet,
            },
          },
        ];
        result = await session.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      }
      else
      {
        console.log("login type not found!")
        return;
      }
      console.log(result);
      
      await delay(2000);
      setRefresh(Date.now())
    } catch (err) {
      console.log(err);
    }
  }

  async function completeNftAct() {
    
    try {
      let result;
      if (logintype === '1') {
        let actions = [      
          {
            account: main_account,
            name: "completenft",
            authorization: [
              {
                actor: wallet,
                permission: "active",
              },
            ],
            data: {
              wallet: wallet,
            },
          },
        ];

        result = await wax.api.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      } else if(logintype === '2' || logintype === '3') {
        
        let session = await link.restoreSession("explorermine");
        let actions = [      
          {
            account: main_account,
            name: "completenft",
            authorization: [
              {
                actor: wallet,
                permission: session.auth.actor,
              },
            ],
            data: {
              wallet: wallet,
            },
          },
        ];
        result = await session.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      }
      else
      {
        console.log("login type not found!")
        return;
      }
      console.log(result);
      
      await delay(2000);
      setRefresh(Date.now())
    } catch (err) {
      console.log(err);
    }
  }

  async function withdrawAct(amount) {    
    try {
      let result;
      if (logintype === '1') {
        let actions = [
          {
            account: main_account,
            name: "withdraw",
            authorization: [
              {
                actor: wallet,
                permission: "active",
              },
            ],
            data: {
              wallet: wallet,
              quantity: amount,
            },
          }
        ];
        result = await wax.api.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      } else if(logintype === '2' || logintype === '3') {
        
        let session = await link.restoreSession("explorermine");
        let actions = [
          {
            account: main_account,
            name: "withdraw",
            authorization: [
              {
                actor: wallet,
                permission: session.auth.permission,
              },
            ],
            data: {
              wallet: wallet,
              quantity: amount,
            },
          }
        ];
        result = await session.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      }
      else
      {
        console.log("login type not found!")
        return;
      }
      console.log(result);
      
      await delay(2000);
      setRefresh(Date.now())
    } catch (err) {
      console.log(err);
    }
  }

  async function depositAct(amount, contract) {    
    try {
      let result;
      console.log("logintype")
      console.log(logintype)
      if (logintype === '1') {
        let actions = [
          {
            account: contract,
            name: "transfer",
            authorization: [
              {
                actor: wallet,
                permission: "active",
              },
            ],
            data: {
              from: wallet,
              to: main_account,
              quantity: amount,
              memo: "explorermine:deposit",
            },
          },
          {
            account: main_account,
            name: "completetkn",
            authorization: [
              {
                actor: wallet,
                permission: "active",
              },
            ],
            data: {
              wallet: wallet,
            },
          }
        ];
        result = await wax.api.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      } else if(logintype === '2' || logintype === '3') {
        
        let session = await link.restoreSession("explorermine");
        
        let actions = [
          {
            account: contract,
            name: "transfer",
            authorization: [
              {
                actor: wallet,
                permission: session.auth.permission,
              },
            ],
            data: {
              from: wallet,
              to: main_account,
              quantity: amount,
              memo: "explorermine:deposit",
            },
          },
          {
            account: main_account,
            name: "completetkn",
            authorization: [
              {
                actor: wallet,
                permission: session.auth.permission,
              },
            ],
            data: {
              wallet: wallet,
            },
          }
        ];
        result = await session.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      }
      else
      {
        console.log("login type not found!")
        return;
      }
      console.log(result);
      
      await delay(2000);
      setRefresh(Date.now())
    } catch (err) {
      console.log(err);
    }
  }

  async function completeTknAct() {
    try {
      let result;
      if (logintype === '1') {
        let actions = [      
          {
            account: main_account,
            name: "completetkn",
            authorization: [
              {
                actor: wallet,
                permission: "active",
              },
            ],
            data: {
              wallet: wallet,
            },
          },
        ];

        result = await wax.api.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      } else if(logintype === '2' || logintype === '3') {
        
        let session = await link.restoreSession("explorermine");
        
        let actions = [      
          {
            account: main_account,
            name: "completetkn",
            authorization: [
              {
                actor: wallet,
                permission: session.auth.permission,
              },
            ],
            data: {
              wallet: wallet,
            },
          },
        ];

        result = await session.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      }
      else
      {
        console.log("login type not found!")
        return;
      }
      console.log(result);
      
      await delay(2000);
      setRefresh(Date.now())
    } catch (err) {
      console.log(err);
    }
  }

  async function BidAct(amount, id) {
    try {
      let result;
      if (logintype === '1') {
        let actions = [
          {
            account: main_account,
            name: "bid",
            authorization: [
              {
                actor: wallet,
                permission: "active",
              },
            ],
            data: {
              wallet: wallet,
              id: id,
              quantity: amount,
            },
          }
        ];
        result = await wax.api.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      } else if(logintype === '2' || logintype === '3') {
        
        let session = await link.restoreSession("explorermine");
        let actions = [
          {
            account: main_account,
            name: "bid",
            authorization: [
              {
                actor: wallet,
                permission: session.auth.permission,
              },
            ],
            data: {
              wallet: wallet,
              id: id,
              quantity: amount,
            },
          }
        ];
        result = await session.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      }
      else
      {
        console.log("login type not found!")
        return;
      }
      console.log(result);
      
      await delay(2000);
      setRefresh(Date.now())
    } catch (err) {
      console.log(err);
    }
  }

  async function ClaimAuctionAct(id) {    
    try {
      let result;
      if (logintype === '1') {
        let actions = [
          {
            account: main_account,
            name: "claimauction",
            authorization: [
              {
                actor: wallet,
                permission: "active",
              },
            ],
            data: {
              wallet: wallet,
              id: id,
            },
          }
        ];
        result = await wax.api.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      } else if(logintype === '2' || logintype === '3') {
        
        let session = await link.restoreSession("explorermine");
        let actions = [
          {
            account: main_account,
            name: "claimauction",
            authorization: [
              {
                actor: wallet,
                permission: session.auth.permission,
              },
            ],
            data: {
              wallet: wallet,
              id: id,
            },
          }
        ];
        result = await session.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      }
      else
      {
        console.log("login type not found!")
        return;
      }
      console.log(result);
      
      await delay(2000);
      setRefresh(Date.now())
    } catch (err) {
      console.log(err);
    }
  }

  async function ClaimEscrowAct(id) {    
    try {
      let result;
      if (logintype === '1') {
        let actions = [
          {
            account: main_account,
            name: "claimescrow",
            authorization: [
              {
                actor: wallet,
                permission: "active",
              },
            ],
            data: {
              wallet: wallet,
              id: id,
            },
          }
        ];
    
        result = await wax.api.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      } else if(logintype === '2' || logintype === '3') {
        
        let session = await link.restoreSession("explorermine");
        let actions = [
          {
            account: main_account,
            name: "claimescrow",
            authorization: [
              {
                actor: wallet,
                permission: session.auth.permission,
              },
            ],
            data: {
              wallet: wallet,
              id: id,
            },
          }
        ];
    
        result = await session.transact(
          {
            actions: actions,
          },
          {
            blocksBehind: 3,
            expireSeconds: 1200,
          }
        );
      }
      else
      {
        console.log("login type not found!")
        return;
      }
      console.log(result);
      
      await delay(2000);
      setRefresh(Date.now())
    } catch (err) {
      console.log(err);
    }
  }

  const renderContent = () => {
    switch (currentPage) {
      case 'inventory':
        return <Inventory 
        wallet={wallet}
        selectedWallet={selectedWallet}
        nftConfigs={nftConfigs}
        poolConfigs={poolConfigs}
        navigateToSection={navigateToSection}
        unstakeAct={unstakeAct}
        refresh={refresh}
        stakeAct={stakeAct}
        completeNftAct={completeNftAct}
        userData={userData}
        />;
      case 'wallet':
        return <Wallet 
        wallet={wallet}
        selectedWallet={selectedWallet}
        userData={userData}
        withdrawAct={withdrawAct}
        refresh={refresh}
        depositAct={depositAct}
        completeTknAct={completeTknAct}
        />;
      case 'pools':
        return <Pools />;
      case 'auctions':
        return <Auctions 
        wallet={wallet}
        selectedWallet={selectedWallet}
        userData={userData}
        refresh={refresh}
        BidAct={BidAct}
        ClaimAuctionAct={ClaimAuctionAct}
        />;
      case 'resource':
        return <Resource 
        wallet={wallet}
        selectedWallet={selectedWallet}
        />;
      default:
        return <Dashboard
        wallet={wallet}
        selectedWallet={selectedWallet}
        userData={userData}
        nftConfigs={nftConfigs}
        poolConfigs={poolConfigs}
        claimAct={claimAct}
        registerAct={registerAct}
        navigateToSection={navigateToSection}
        refresh={refresh}
        ClaimEscrowAct={ClaimEscrowAct}
        BidAct={BidAct}
        ClaimAuctionAct={ClaimAuctionAct}
        />;
    }
  };

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  };

  const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const deleteCookie = (name) => {
    document.cookie = name + "=; Max-Age=0; path=/;";
  };

  return (
    <div className="theopenspace-stake-container">
      <div className={`sidebar`}>
        <div className="wallet-connect">
          {(!wallet || !getCookie('walletAddress')) && (
            <button className="connect-wallet-button" onClick={() => setShowModal(true)}>
              <i className="fas fa-wallet"></i> Connect Wallet
            </button>
          )}
          {wallet && (
            <button className="stake-wallet-address-button" onClick={updateAddressInURL}>
              <i className="fas fa-wallet"></i> {wallet}
            </button>
          )}
        </div>

        <div className="stake-menu">
        <button
          className={`stake-menu-item ${currentPage === 'dashboard' ? 'active' : ''}`}
          onClick={() => navigateToSection('dashboard')}
        >
          Dashboard
        </button>
        <button
          className={`stake-menu-item ${currentPage === 'inventory' ? 'active' : ''}`}
          onClick={() => navigateToSection('inventory')}
        >
          Inventory
        </button>
        <button
          className={`stake-menu-item ${currentPage === 'wallet' ? 'active' : ''}`}
          onClick={() => navigateToSection('wallet')}
        >
          Wallet
        </button>
        <button
          className={`stake-menu-item ${currentPage === 'pools' ? 'active' : ''}`}
          onClick={() => navigateToSection('pools')}
        >
          Pools
        </button>
        <button
          className={`stake-menu-item ${currentPage === 'auctions' ? 'active' : ''}`}
          onClick={() => navigateToSection('auctions')}
        >
          Auctions
        </button>
        <button
          className={`stake-menu-item ${currentPage === 'resource' ? 'active' : ''}`}
          onClick={() => navigateToSection('resource')}
          style={{ display: selectedWallet ? 'block' : 'none' }} // Butonun gösterimini kontrol eden kısım
        >
          Resource
        </button>
        {wallet && getCookie('walletAddress') && (
          <button className="stake-menu-item logout-item" onClick={handleLogout}>
            Logout
          </button>
        )}
      </div>
      </div>

      <div className="main-content">
        {renderContent()}
      </div>

      {showModal && (
        <WalletConnectModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleLogin={handleLogin}
        />
      )}
    </div>
  );
}

function WalletConnectModal({ showModal, setShowModal, handleLogin }) {
  return (
    <>
      {showModal && (
        <div className="wallet-modal">
          <div className="wallet-modal-content">
            <h3>Select Wallet</h3>
            <button className="wallet-button cloudwallet" onClick={() => handleLogin('cloudwallet')}>
              <img src={mycloud_w} alt="Cloud Wallet" />
              My Cloud Wallet
            </button>
            <button className="wallet-button anchorwallet" onClick={() => handleLogin('anchor')}>
              <img src={anchor_w} alt="Anchor Wallet" />
              Anchor Wallet
            </button>
            <button className="cancel-button" onClick={() => setShowModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default ExplorerminePlay;
