import * as waxjs from "@waxio/waxjs/dist";

const apiEndpoints = ["https://wax.greymass.com"];
const testapiEndpoints = ["https://waxtestnet.greymass.com"];

const main_account = "explorermine";

function getRandomApiEndpoint() {
  const randomIndex = Math.floor(Math.random() * apiEndpoints.length);
  return apiEndpoints[randomIndex];
}

function getRandomTestApiEndpoint() {
  const randomTestIndex = Math.floor(Math.random() * testapiEndpoints.length);
  return testapiEndpoints[randomTestIndex];
}

let wax = new waxjs.WaxJS({
  rpcEndpoint: getRandomTestApiEndpoint(),
  tryAutoLogin: false
});

export const fetchUserData = async (selectedWallet) => {
  try {
    const accountsTable = await wax.rpc.get_table_rows({
      json: true,
      code: main_account,
      scope: main_account,
      table: 'accounts',
      lower_bound: selectedWallet,
      upper_bound: selectedWallet,
      limit: 1,
    });

    if (accountsTable.rows.length > 0) {
      return accountsTable.rows[0];
    } else {
      console.log('User record not found.');
      return null;
    }
  } catch (error) {
    console.error('Error occurred while fetching table data:', error);
    throw error;
  }
};

export const fetchNftConfigs = async () => {
  try {
    let configTable = await wax.rpc.get_table_rows({
      json: true,
      code: main_account,
      scope: main_account,
      table: 'nftconfig',
      limit: 100, // Attempting to fetch up to 1000 records initially
      reverse: false, // Oldest records come first
      show_payer: false, // Do not show payer information
    });

    let config = configTable.rows.filter((a) => {          
        return JSON.stringify(a);
    });

    while (configTable.more === true) {
        configTable = await wax.rpc.get_table_rows({
          json: true,
          code: main_account,
          scope: main_account,
          table: 'nftconfig',
          lower_bound: configTable.next_key,
          limit: 100, // Maximum number of rows that we want to get
          reverse: false, // Optional: Get reversed data
          show_payer: false, // Optional: Show ram payer
        });

        config.push(
          ...configTable.rows.filter((a) => {              
              return JSON.stringify(a);
          })
        );
      }

    if (config.length > 0) {
      return config; // Return all rows
    } else {
      console.log('Configuration data not found.');
      return [];
    }
  } catch (error) {
    console.error('Error occurred while fetching configuration data:', error);
    throw error;
  }
};

export const fetchPools = async () => {
    try {
      let configTable = await wax.rpc.get_table_rows({
        json: true,
        code: main_account,
        scope: main_account,
        table: 'pools',
        limit: 100, // Attempting to fetch up to 1000 records initially
        reverse: false, // Oldest records come first
        show_payer: false, // Do not show payer information
      });
  
      let config = configTable.rows.filter((a) => {          
          return JSON.stringify(a);
      });
  
      while (configTable.more === true) {
          configTable = await wax.rpc.get_table_rows({
            json: true,
            code: main_account,
            scope: main_account,
            table: 'pools',
            lower_bound: configTable.next_key,
            limit: 100, // Maximum number of rows that we want to get
            reverse: false, // Optional: Get reversed data
            show_payer: false, // Optional: Show ram payer
          });
  
          config.push(
            ...configTable.rows.filter((a) => {              
                return JSON.stringify(a);
            })
          );
        }
  
      if (config.length > 0) {
        return config; // Return all rows
      } else {
        console.log('Pools data not found.');
        return [];
      }
    } catch (error) {
      console.error('Error occurred while fetching pools data:', error);
      throw error;
    }
};

export const fetchUserHistory = async (lastclaim) => {
  try {
    let configTable = await wax.rpc.get_table_rows({
      json: true,
      code: main_account,
      scope: main_account,
      table: 'history',
      lower_bound: lastclaim,
      limit: 100, // Attempting to fetch up to 1000 records initially
      reverse: false, // Oldest records come first
      show_payer: false, // Do not show payer information
    });

    let config = configTable.rows.filter((a) => {          
        return JSON.stringify(a);
    });

    while (configTable.more === true) {
        configTable = await wax.rpc.get_table_rows({
          json: true,
          code: main_account,
          scope: main_account,
          table: 'history',
          lower_bound: configTable.next_key,
          limit: 100, // Maximum number of rows that we want to get
          reverse: false, // Optional: Get reversed data
          show_payer: false, // Optional: Show ram payer
        });

        config.push(
          ...configTable.rows.filter((a) => {              
              return JSON.stringify(a);
          })
        );
      }

    if (config.length > 0) {
      return config; // Return all rows
    } else {
      console.log('History data not found.');
      return [];
    }
  } catch (error) {
    console.error('Error occurred while fetching History data:', error);
    throw error;
  }
};

export const fetchUserInventory = async (address) => {
  try {
    let configTable = await wax.rpc.get_table_rows({
      json: true,
      code: main_account,
      scope: address,
      table: 'staked',
      limit: 100, // Attempting to fetch up to 1000 records initially
      reverse: false, // Oldest records come first
      show_payer: false, // Do not show payer information
    });

    let config = configTable.rows.filter((a) => {          
        return JSON.stringify(a);
    });

    while (configTable.more === true) {
        configTable = await wax.rpc.get_table_rows({
          json: true,
          code: main_account,
          scope: address,
          table: 'staked',
          lower_bound: configTable.next_key,
          limit: 100, // Maximum number of rows that we want to get
          reverse: false, // Optional: Get reversed data
          show_payer: false, // Optional: Show ram payer
        });

        config.push(
          ...configTable.rows.filter((a) => {              
              return JSON.stringify(a);
          })
        );
      }

    if (config.length > 0) {
      return config; // Return all rows
    } else {
      console.log('Inventory data not found.');
      return [];
    }
  } catch (error) {
    console.error('Error occurred while fetching Inventory data:', error);
    throw error;
  }
};

export const fetchUserNfts = async (address) => {
  try {
    let configTable = await wax.rpc.get_table_rows({
      json: true,
      code: "atomicassets",
      scope: address,
      table: 'assets',
      limit: 100, // Attempting to fetch up to 1000 records initially
      reverse: false, // Oldest records come first
      show_payer: false, // Do not show payer information
    });

    let config = configTable.rows.filter((a) => {          
        return JSON.stringify(a);
    });

    while (configTable.more === true) {
        configTable = await wax.rpc.get_table_rows({
          json: true,
          code: "atomicassets",
          scope: address,
          table: 'assets',
          lower_bound: configTable.next_key,
          limit: 100, // Maximum number of rows that we want to get
          reverse: false, // Optional: Get reversed data
          show_payer: false, // Optional: Show ram payer
        });

        config.push(
          ...configTable.rows.filter((a) => {              
              return JSON.stringify(a);
          })
        );
      }

    if (config.length > 0) {
      return config; // Return all rows
    } else {
      console.log('Wallet NFts data not found.');
      return [];
    }
  } catch (error) {
    console.error('Error occurred while fetching Wallet NFts data:', error);
    throw error;
  }
};

export const fetchUserCompNFT = async (address) => {
  try {
    let configTable = await wax.rpc.get_table_rows({
      json: true,
      code: main_account,
      scope: address,
      table: 'temporarynft',
      limit: 100, // Attempting to fetch up to 1000 records initially
      reverse: false, // Oldest records come first
      show_payer: false, // Do not show payer information
    });

    let config = configTable.rows.filter((a) => {          
        return JSON.stringify(a);
    });

    while (configTable.more === true) {
        configTable = await wax.rpc.get_table_rows({
          json: true,
          code: main_account,
          scope: address,
          table: 'temporarynft',
          lower_bound: configTable.next_key,
          limit: 100, // Maximum number of rows that we want to get
          reverse: false, // Optional: Get reversed data
          show_payer: false, // Optional: Show ram payer
        });

        config.push(
          ...configTable.rows.filter((a) => {              
              return JSON.stringify(a);
          })
        );
      }

    if (config.length > 0) {
      return config; // Return all rows
    } else {      
      return [];
    }
  } catch (error) {
    console.error('Error occurred while fetching Wallet Temp NFts data:', error);
    throw error;
  }
};

export const fetchTimers = async () => {
  try {
    let configTable = await wax.rpc.get_table_rows({
      json: true,
      code: main_account,
      scope: main_account,
      table: 'timercounter',
      limit: 1, // Sadece tek bir kayıt getirilecek
      reverse: false, // En eski kayıt önce gelecek
      show_payer: false, // Payer bilgisi gösterilmeyecek
    });

    // Gelen veriyi direkt olarak alıyoruz
    let config = configTable.rows[0]; // İlk (ve tek) satırı alıyoruz

    if (config) {
      return config; // Veriyi geri döndürüyoruz
    } else {
      console.log('TimerCounter data not found.');
      return null; // Eğer veri yoksa null döndürüyoruz
    }
  } catch (error) {
    console.error('Error occurred while fetching TimerCounter data:', error);
    throw error;
  }
};

export const fetchTokens = async () => {
  try {
    let configTable = await wax.rpc.get_table_rows({
      json: true,
      code: main_account,
      scope: main_account,
      table: 'tokens',
      limit: 100, // Attempting to fetch up to 1000 records initially
      reverse: false, // Oldest records come first
      show_payer: false, // Do not show payer information
    });

    let config = configTable.rows.filter((a) => {          
        return JSON.stringify(a);
    });

    while (configTable.more === true) {
        configTable = await wax.rpc.get_table_rows({
          json: true,
          code: main_account,
          scope: main_account,
          table: 'tokens',
          lower_bound: configTable.next_key,
          limit: 100, // Maximum number of rows that we want to get
          reverse: false, // Optional: Get reversed data
          show_payer: false, // Optional: Show ram payer
        });

        config.push(
          ...configTable.rows.filter((a) => {              
              return JSON.stringify(a);
          })
        );
      }

    if (config.length > 0) {
      return config; // Return all rows
    } else {
      console.log('tokens data not found.');
      return [];
    }
  } catch (error) {
    console.error('Error occurred while fetching tokens data:', error);
    throw error;
  }
};


export const getCurrentFee = async () => {
  try {
    let configTable = await wax.rpc.get_table_rows({
      json: true,
      code: "theopenspace",
      scope: "theopenspace",
      table: 'settings',
      limit: 1, // Sadece tek bir kayıt getirilecek
      reverse: false, // En eski kayıt önce gelecek
      show_payer: false, // Payer bilgisi gösterilmeyecek
    });

    // Gelen veriyi direkt olarak alıyoruz
    let config = configTable.rows[0]; // İlk (ve tek) satırı alıyoruz

    if (config) {
      return config; // Veriyi geri döndürüyoruz
    } else {
      console.log('CurrentFee data not found.');
      return null; // Eğer veri yoksa null döndürüyoruz
    }
  } catch (error) {
    console.error('Error occurred while fetching CurrentFee data:', error);
    throw error;
  }
};
  
export const getUserTokens = async (address) => {
  try {
    // Sisteminizde kayıtlı token kontratlarını ve sembollerini çekiyoruz
    const tokens = await fetchTokens();
    
    // Eğer tokens null veya boş ise, boş bir liste döneriz
    if (!tokens || tokens.length === 0) {
      return [];
    }

    // Kullanıcı token bakiyelerini tutacak yeni bir liste oluşturuyoruz
    const userBalances = [];

    // Her bir token için döngü oluşturuyoruz
    for (const token of tokens) {
      const [, symbol] = token.token_symbol.split(','); // Virgül ile split ederek sembolü elde ediyoruz
      const contract = token.contract; // Token kontratını alıyoruz

      // Kullanıcının belirli bir kontrat ve sembole sahip token bakiyesini alıyoruz
      const avail_token = await wax.rpc.get_currency_balance(
        contract,
        address,
        symbol
      );
      
      // Eğer kullanıcıda bu token'dan bir bakiye varsa, listeye ekliyoruz
      if (avail_token.length > 0) {
        const balance = avail_token[0].split(' ')[0]; // Bakiye kısmını alıyoruz, örn: "100.0000"
        
        // Hassasiyeti belirlemek için noktadan sonrasına bakıyoruz
        let precisionValue = 0;
        if (balance.includes('.')) {
          precisionValue = balance.split('.')[1].length; // Noktadan sonraki basamak sayısı
        }

        userBalances.push({
          contract,
          symbol,
          balance: avail_token[0], // Burada avail_token bir string döner örn: "100.0000 TOKEN"
          precision: precisionValue // Token hassasiyetini ekliyoruz
        });
      }
    }

    // Kullanıcının token bakiyeleri listesini döndürüyoruz
    return userBalances;
  } catch (error) {
    console.error('Error occurred while fetching user token balances:', error);
    throw error;
  }
};

export const fetchUserCompToken = async (address) => {
  try {
    let configTable = await wax.rpc.get_table_rows({
      json: true,
      code: main_account,
      scope: address,
      table: 'temporarytkn',
      limit: 100, // Attempting to fetch up to 1000 records initially
      reverse: false, // Oldest records come first
      show_payer: false, // Do not show payer information
    });

    let config = configTable.rows.filter((a) => {          
        return JSON.stringify(a);
    });

    while (configTable.more === true) {
        configTable = await wax.rpc.get_table_rows({
          json: true,
          code: main_account,
          scope: address,
          table: 'temporarytkn',
          lower_bound: configTable.next_key,
          limit: 100, // Maximum number of rows that we want to get
          reverse: false, // Optional: Get reversed data
          show_payer: false, // Optional: Show ram payer
        });

        config.push(
          ...configTable.rows.filter((a) => {              
              return JSON.stringify(a);
          })
        );
      }

    if (config.length > 0) {
      return config; // Return all rows
    } else {      
      return [];
    }
  } catch (error) {
    console.error('Error occurred while fetching Wallet Temp Tokens data:', error);
    throw error;
  }
};

export const fetchAuctions = async () => {
  try {
    let configTable = await wax.rpc.get_table_rows({
      json: true,
      code: main_account,
      scope: main_account,
      table: 'auctions',
      limit: 100, // Attempting to fetch up to 1000 records initially
      reverse: true, // Oldest records come first
      show_payer: false, // Do not show payer information
    });

    let config = configTable.rows.filter((a) => {          
        return JSON.stringify(a);
    });

    while (configTable.more === true) {
        configTable = await wax.rpc.get_table_rows({
          json: true,
          code: main_account,
          scope: main_account,
          table: 'auctions',
          upper_bound: configTable.next_key,
          limit: 100, // Maximum number of rows that we want to get
          reverse: true, // Optional: Get reversed data
          show_payer: false, // Optional: Show ram payer
        });

        config.push(
          ...configTable.rows.filter((a) => {              
              return JSON.stringify(a);
          })
        );
      }

    if (config.length > 0) {
      return config; // Return all rows
    } else {
      console.log('auctions data not found.');
      return [];
    }
  } catch (error) {
    console.error('Error occurred while fetching auctions data:', error);
    throw error;
  }
};

export const fetchRewardNfts = async () => {
  try {
    let configTable = await wax.rpc.get_table_rows({
      json: true,
      code: main_account,
      scope: main_account,
      table: 'nftrewards',
      limit: 100, // Attempting to fetch up to 1000 records initially
      reverse: false, // Oldest records come first
      show_payer: false, // Do not show payer information
    });

    let config = configTable.rows.filter((a) => {          
        return JSON.stringify(a);
    });

    while (configTable.more === true) {
        configTable = await wax.rpc.get_table_rows({
          json: true,
          code: main_account,
          scope: main_account,
          table: 'nftrewards',
          lower_bound: configTable.next_key,
          limit: 100, // Maximum number of rows that we want to get
          reverse: false, // Optional: Get reversed data
          show_payer: false, // Optional: Show ram payer
        });

        config.push(
          ...configTable.rows.filter((a) => {              
              return JSON.stringify(a);
          })
        );
      }

    if (config.length > 0) {
      return config; // Return all rows
    } else {
      console.log('nftrewards data not found.');
      return [];
    }
  } catch (error) {
    console.error('Error occurred while fetching nftrewards data:', error);
    throw error;
  }
};

export const getUserBeforeBids = async (id, wallet) => {
  try {
    let configTable = await wax.rpc.get_table_rows({
      json: true,
      code: main_account,
      scope: wallet,
      table: 'escrow',
      lower_bound: id,
      upper_bound: id,
      limit: 1, // Sadece tek bir kayıt getirilecek
      reverse: false, // En eski kayıt önce gelecek
      show_payer: false, // Payer bilgisi gösterilmeyecek
    });

    // Gelen veriyi direkt olarak alıyoruz
    let config = configTable.rows[0]; // İlk (ve tek) satırı alıyoruz

    if (config) {
      return config; // Veriyi geri döndürüyoruz
    } else {
      console.log('this id bid not found.');
      return null; // Eğer veri yoksa null döndürüyoruz
    }
  } catch (error) {
    console.error('Error occurred while fetching user bids data:', error);
    throw error;
  }
};

export const fetchAuction = async (id) => {
  try {
    let configTable = await wax.rpc.get_table_rows({
      json: true,
      code: main_account,
      scope: main_account,
      table: 'auctions',
      lower_bound: id,
      upper_bound: id,
      limit: 1, // Sadece tek bir kayıt getirilecek
      reverse: false, // En eski kayıt önce gelecek
      show_payer: false, // Payer bilgisi gösterilmeyecek
    });

    // Gelen veriyi direkt olarak alıyoruz
    let config = configTable.rows[0]; // İlk (ve tek) satırı alıyoruz

    if (config) {
      return config; // Veriyi geri döndürüyoruz
    } else {
      console.log('auction not found.');
      return null; // Eğer veri yoksa null döndürüyoruz
    }
  } catch (error) {
    console.error('Error occurred while fetching auction data:', error);
    throw error;
  }
};


export const fetchUserResource = async (address) => {
  try {
    const rpcAccount = await wax.rpc.get_account(address);
    let resorces = {
      cpu: rpcAccount.cpu_limit,
      net: rpcAccount.net_limit,
      ram_quota: rpcAccount.ram_quota,
      ram_usage: rpcAccount.ram_usage,
    };

    if (resorces) {
      return resorces; // Veriyi geri döndürüyoruz
    } else {
      console.log('resource not found.');
      return null; // Eğer veri yoksa null döndürüyoruz
    }
  } catch (error) {
    console.error('Error occurred while fetching resource data:', error);
    throw error;
  }
};

export const getUserWaxBalance = async (address) => {
  try {
    let wax_token = await wax.rpc.get_currency_balance(
      "eosio.token",
      address,
      "WAX"
    );

    if (wax_token) {
      return wax_token; // Veriyi geri döndürüyoruz
    } else {
      console.log('wax balance not found.');
      return null; // Eğer veri yoksa null döndürüyoruz
    }
  } catch (error) {
    console.error('Error occurred while fetching wax balance :', error);
    throw error;
  }
};


