import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoginDropdown from '../Login/LoginDropdown';
import logo from '../../assets/images/ecosoft_logo.png';
import './Header.css';

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const games = [
    { name: 'The Open Space', link: '/theopenspace' },
    { name: 'Explorer Mine', link: '/explorermine' },
  ];

  return (
    <nav className={`navbar navbar-expand-lg navbar-dark bg-dark ${scrolled ? 'scrolled' : ''}`}>
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <div className="navbar-left d-flex align-items-center">
          <Link className="navbar-brand" to="/">
            <div className="logo-container">
              <img src={logo} alt="Ecosoft Games Logo" className="logo" />
              <div className="logo-text">
                Ecosoft<br />Games
              </div>
            </div>
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/home">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">About</Link>
            </li>            
            <li className="nav-item">
              <Link className="nav-link" to="/marketplace">Marketplace</Link>
            </li>
            <li
              className="nav-item dropdown"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Link className="nav-link" to="/projects">
                Projects
              </Link>
              <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
                {games.map((game, index) => (
                  <Link key={index} className="dropdown-item" to={game.link}>
                    {game.name}
                  </Link>
                ))}
              </div>
            </li>
          </ul>
        </div>
        <div className="navbar-right d-flex">
          <LoginDropdown onLogin={() => {}} />
        </div>
      </div>
    </nav>
  );
}

export default Header;
