import React from 'react';
import { Link } from 'react-router-dom';
import './Theopenspace.css';
import { getIpfsImageUrl } from '../../../components/IpfsService';

function Theopenspace() {
  const buttons = [
    { name: 'Play', link: '/theopenspace/play' },
    { name: 'Marketplace', link: '/marketplace' },
    { name: 'Whitepaper', link: 'https://yourgitbookurl.com/whitepaper' },
  ];
  const logoImage = "QmSPQFi9pEh3nZYEDBaJ9Ppb7jXEyNaWLQVC2tfgFnKJL1";
  const aboutSections = [
    {
      title: 'Massive Open-World Exploration',
      description: 'Explore a vast and ever-expanding universe filled with countless planets, stars, and hidden secrets.',
      image: '../../../assets/images/test_banner.png',
      button: { text: 'Learn More', link: '/theopenspace/exploration' },
    },
    {
      title: 'Resource Mining and Management',
      description: 'Mine valuable resources from asteroids and planets to fuel your empire’s growth.',
      image: '../../../assets/images/test_banner.png',
    },
    {
      title: 'Empire Building and Space Conquest',
      description: 'Build and manage your own space empire, conquer new territories, and expand your influence across the galaxy.',
      image: '../../../assets/images/test_banner.png',
      button: { text: 'Start Now', link: '/theopenspace/empire' },
    },
  ];

  const handleButtonClick = (link) => {
    if (link.startsWith('http') || link.startsWith('https')) {
      window.open(link, '_blank', 'noopener noreferrer');
    } else {
      window.location.href = link;
    }
  };

  return (
    <div className="theopenspace-page">
      <header className="theopenspace-header">
        <div className="theopenspace-header-content">
          <img src={getIpfsImageUrl(logoImage)} alt="The Open Space Logo" className="game-logo" />
          <div className="theopenspace-title">
            <h1>The Open Space</h1>
            <p>Explore the vast universe, mine resources, and build your empire.</p>
          </div>
        </div>
      </header>

      <div className="theopenspace-actions">
        {buttons.map((button, index) => (
          <button
            key={index}
            className="theopenspace-btn"
            onClick={() => handleButtonClick(button.link)}
          >
            {button.name}
          </button>
        ))}
      </div>

      <section className="theopenspace-about-sections">
        {aboutSections.map((section, index) => (
          <div key={index} className="theopenspace-card">
            <img src={section.image} alt={section.title} className="theopenspace-card-img" />
            <div className="theopenspace-card-body">
              <h5 className="theopenspace-card-title">{section.title}</h5>
              <p className="theopenspace-card-text">{section.description}</p>
              {section.button && (
                <button
                  className="theopenspace-btn"
                  onClick={() => handleButtonClick(section.button.link)}
                >
                  {section.button.text}
                </button>
              )}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}

export default Theopenspace;
