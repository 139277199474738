import React from 'react';
import './Games.css';
import { getIpfsImageUrl } from '../IpfsService';
import { Link } from 'react-router-dom';

function GamesSection() {
  const games = [
    {
      name: 'The Open Space',
      banner: '/images/theopenspace-banner.jpg',
      logo: "QmSPQFi9pEh3nZYEDBaJ9Ppb7jXEyNaWLQVC2tfgFnKJL1",
      description: 'Explore the vast universe, mine resources, and build your empire in The Open Space.',
      link: '/theopenspace'
    },
    {
      name: 'Explorer Mine',
      banner: '/images/theopenspace-banner.jpg',
      logo: "Qmcq9ogP29mNK1gP3VAVqSqeLPwpR8uZk4v1XUSB4GJb7k",
      description: 'Participants use their NFTs to earn tokens from reward pools and join auctions to win NFT rewards.',
      link: '/explorermine'
    },
  ];

  return (
    <div className="games-comp-container mt-5">
      <h2 className="games-comp-title mb-5">Our Projects</h2>
      <div className="games-comp-list">
        {games.map((game, index) => (
          <div key={index} className="games-comp-item" style={{ backgroundImage: `url(${game.banner})` }}>
            <div className="games-comp-content">
              <img src={getIpfsImageUrl(game.logo)} alt={game.name} />
              <div>
                <h3>{game.name}</h3>
                <p>{game.description}</p>
              </div>
              <button className="games-comp-custom-button" onClick={() => window.location.href = game.link}>
                Visit Page
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GamesSection;
