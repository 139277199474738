import React, { useState, useEffect, useCallback } from 'react';
import './Auctions.css'; // Import the CSS file
import { fetchAuctions, fetchRewardNfts } from '../contractService'; // Fetch auctions and NFTs
import { getIpfsImageUrl } from '../../../../components/IpfsService'; // Import the IPFS service for images
import BidModal from '../BidModal'; // BidModal bileşenini import edin

const Auctions = ({ wallet, selectedWallet, userData, refresh, BidAct, ClaimAuctionAct }) => {
  const [auctionItems, setAuctionItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000)); // UTC epoch time
  const [sortOrder, setSortOrder] = useState('default');
  const [showEnded, setShowEnded] = useState(false); 
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [rewardNfts, setRewardNfts] = useState([]); // Update to array to handle multiple NFTs
  const [owner, setOwner] = useState(false);
  const [collectionFilter, setCollectionFilter] = useState('All'); // Add collection filter
  const [templateFilter, setTemplateFilter] = useState('All'); // Add template filter
  const [isBidModalOpen, setIsBidModalOpen] = useState(false); // Modalın açılma durumunu kontrol etmek için
  const [selectedAuctionData, setSelectedAuctionData] = useState(null); // Hangi auction için bid yapıldığını saklamak için

  useEffect(() => {
    if (wallet && wallet === selectedWallet) {
      setOwner(true);
    } else {
      setOwner(false);
    }
  }, [wallet, selectedWallet]);

  useEffect(() => {
    const getAuctions = async () => {
      try {
        const auctions = await fetchAuctions();
        setAuctionItems(auctions);
        setFilteredItems(auctions); // Initialize filtered items

        // Fetch reward NFTs for each auction
        const nftData = await fetchRewardNfts();
        setRewardNfts(nftData);

      } catch (error) {
        console.error('Error fetching auctions or NFTs:', error);
      } finally {
        setIsLoading(false); // Veriler çekildiğinde yüklenme biter
      }
    };

    getAuctions();

    const interval = setInterval(() => {
      setCurrentTime(Math.floor(Date.now() / 1000));
    }, 1000);

    return () => clearInterval(interval);
  }, [refresh]);

  const filterAndSortAuctions = useCallback(() => {
    let filtered = auctionItems.filter((item) => {
      const endTime = item.finish_time + item.extra_time;
      const isEnded = currentTime >= endTime;
      const nftDetails = rewardNfts.find(nft => nft.template_id === item.template_id);

      if (collectionFilter !== 'All' && nftDetails?.collection !== collectionFilter) {
        return false;
      }

      if (templateFilter !== 'All' && item.template_id !== parseInt(templateFilter)) {
        return false;
      }

      if (!showEnded && isEnded) return false;

      return true;
    });

    if (sortOrder === 'nearest') {
      filtered.sort((a, b) => (a.finish_time + a.extra_time) - (b.finish_time + b.extra_time));
    } else if (sortOrder === 'furthest') {
      filtered.sort((a, b) => (b.finish_time + b.extra_time) - (a.finish_time + a.extra_time));
    }

    setFilteredItems(filtered);
  }, [auctionItems, currentTime, collectionFilter, templateFilter, showEnded, sortOrder, rewardNfts]);

  useEffect(() => {
    filterAndSortAuctions();
  }, [sortOrder, showEnded, auctionItems, collectionFilter, templateFilter, refresh, filterAndSortAuctions]);

  const formatDate = (epochTime) => {
    return new Date(epochTime * 1000).toLocaleString();
  };

  const calculateRemainingTime = (endTime) => {
    const timeDiff = endTime - currentTime;
    const hours = Math.floor(timeDiff / 3600);
    const minutes = Math.floor((timeDiff % 3600) / 60);
    const seconds = timeDiff % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const getUniqueCollections = () => {
    const collections = rewardNfts.map(nft => nft.collection);
    return ['All', ...new Set(collections)];
  };

  const getUniqueTemplateIds = () => {
    const templateIds = rewardNfts.map(nft => nft.template_id);
    return ['All', ...new Set(templateIds)];
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleCollectionFilterChange = (e) => {
    setCollectionFilter(e.target.value);
  };

  const handleTemplateFilterChange = (e) => {
    setTemplateFilter(e.target.value);
  };

  const handleShowEndedChange = (e) => {
    setShowEnded(e.target.checked);
  };

  const handleBidClick = (id) => {
    const auction = auctionItems.find(item => item.id === id);
    if (auction) {
      setSelectedAuctionData(auction); 
      setIsBidModalOpen(true);  
    }
  };

  const handleClaimClick = async (id) => {
    console.log('Claim clicked for auction id:', id);
    await ClaimAuctionAct(id);
  };

  const handleConfirmBid = async (amount, id) => {
    await BidAct(amount, id);
    setIsBidModalOpen(false);
  };

  const formatExtraTime = (extraTimeInSeconds) => {
    if (extraTimeInSeconds < 60) {
      return `${extraTimeInSeconds} seconds`;
    } else if (extraTimeInSeconds < 3600) {
      const minutes = Math.floor(extraTimeInSeconds / 60);
      return `${minutes} minutes`;
    } else {
      const hours = Math.floor(extraTimeInSeconds / 3600);
      const minutes = Math.floor((extraTimeInSeconds % 3600) / 60);
      return `${hours} hours ${minutes > 0 ? minutes + ' minutes' : ''}`;
    }
  };

  return (
    <div className="auctions">
      <h2>Auctions</h2>
      
      <div className="auctions-actions">
        <label htmlFor="collectionFilter">Collection:</label>
        <select id="collectionFilter" value={collectionFilter} onChange={handleCollectionFilterChange} className="auction-collection">
          {getUniqueCollections().map((collection, index) => (
            <option key={index} value={collection}>
              {collection}
            </option>
          ))}
        </select>

        <label htmlFor="templateFilter">Template ID:</label>
        <select id="templateFilter" value={templateFilter} onChange={handleTemplateFilterChange} className="auction-template">
          {getUniqueTemplateIds().map((templateId, index) => (
            <option key={index} value={templateId}>
              {templateId}
            </option>
          ))}
        </select>

        <label htmlFor="sortOrder">Sort by :</label>
        <select id="sortOrder" value={sortOrder} onChange={handleSortOrderChange} className="auction-sort">
          <option value="default">Default</option>
          <option value="nearest">Nearest</option>
          <option value="furthest">Furthest</option>
        </select>
        
        <label className="custom-checkbox">
          <input
            type="checkbox"
            checked={showEnded}
            onChange={handleShowEndedChange}
          />
          <p>Show Ended Auctions</p>
        </label>
        
      </div>

      <div className="auction-grid">
        {isLoading ? ( 
          <p>Loading...</p>
        ) : filteredItems.length > 0 ? (
          filteredItems.map((item, index) => {
            const endTime = item.finish_time;
            let statusText = '';
            let statusColor = '';
            let remainingTime = '';
            const nftDetails = rewardNfts.find(nft => nft.template_id === item.template_id);

            if (currentTime < item.start_time) {
              statusText = 'Waiting';
              statusColor = 'gray';
            } else if (currentTime < endTime) {
              statusText = 'Live';
              statusColor = 'green';
              remainingTime = calculateRemainingTime(endTime);
            } else if (currentTime < endTime + item.extra_time && item.extra_time > 0) {
              statusText = 'Extended';
              statusColor = 'orange';
              remainingTime = calculateRemainingTime(endTime + item.extra_time);
            } else {
              statusText = 'Ended';
              statusColor = 'red';
            }

            const isLastBidder = item.last_bidders.includes(wallet);

            return (
              <div key={index} className="auction-card">
                <p><strong>ID: </strong>{item.id}</p>
                {(statusText === 'Live' || statusText === 'Extended' )&& (
                  <div className="remaining-time">
                    <p><strong>Remaining Time: </strong>{remainingTime}</p>
                  </div>
                )}
                {nftDetails && (
                  <div className="auction-nft-card">
                    <div className="category-badge"># {nftDetails.template_id}</div>
                    {nftDetails.img ? (
                      <img
                        src={getIpfsImageUrl(nftDetails.img)} 
                        alt={nftDetails.name}
                        className="auction-media"
                      />
                    ) : (
                      <video
                        src={getIpfsImageUrl(nftDetails.video)}
                        className="auction-media"                        
                        autoPlay
                        muted
                        loop
                      />
                    )}
                    <div className="nft-info">
                      <h5>{nftDetails.name_}</h5>
                      <p><strong>Collection:</strong> {nftDetails.collection}</p>
                      <p><strong>Schema:</strong> {nftDetails.schema}</p>
                    </div>
                    <div className="mint-info">
                      <small>{nftDetails.type === 1 ? 'Mint' : 'Pre-mint'}</small>
                    </div>
                  </div>
                )}

                <div className="auction-info">
                  <p><strong>Start:</strong> {formatDate(item.start_time)}</p>
                  <p><strong>End:</strong> {formatDate(item.finish_time)}</p>
                  <p><strong>Extra Time:</strong> {formatExtraTime(item.extra_time)}</p>
                  <p><strong>Price:</strong> {item.price}</p>
                  <p><strong>Last Bidder:</strong> {item.last_bidder || 'None'}</p>
                  <p><strong>Bidders:</strong></p>
                  <div className="bidders-list">
                    <p>{item.last_bidders.length > 0 ? item.last_bidders.slice(0, 10).join(', ') : 'None'}</p>
                  </div>
                </div>
                <div className="auction-status" style={{ color: statusColor }}>
                  {statusText}
                </div>
                <div className="auction-buttons">
                  {statusText === 'Live' && owner && userData &&(
                    <button onClick={() => handleBidClick(item.id)}>Bid</button>
                  )}
                  {statusText === 'Extended' && isLastBidder && userData &&(
                    <button onClick={() => handleBidClick(item.id)}>Bid</button>
                  )}
                  {statusText === 'Ended' && item.last_bidder === wallet && userData &&(
                    <button onClick={() => handleClaimClick(item.id)}>Claim</button>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <p>No auction data found.</p>
        )}
      </div>

      <BidModal
        show={isBidModalOpen}
        onClose={() => setIsBidModalOpen(false)}
        wallet={wallet}
        auctionData={selectedAuctionData}
        remainingTime={selectedAuctionData ? calculateRemainingTime(selectedAuctionData.finish_time + selectedAuctionData.extra_time) : ''} // Kalan süreyi hesapla
        handleConfirmBid={handleConfirmBid}
        userData={userData}
      />
    </div>
  );
};

export default Auctions;
