import React from 'react';
import './Explorermine.css';
import { getIpfsImageUrl, getIpfsBanner } from '../../../components/IpfsService';

function Explorermine() {
  const buttons = [
    { name: 'Play', link: '/explorermine/play' },
    { name: 'Whitepaper', link: 'https://explorermine.ecosoftgames.com' },
  ];

  const logoImage = "Qmcq9ogP29mNK1gP3VAVqSqeLPwpR8uZk4v1XUSB4GJb7k";
  const aboutSections = [
    {
      title: 'The Open Space\'s Collectable NFTs are now in Explorer Mine!',
      description: 'Users can now earn STRDUST rewards by staking The Open Space\'s Collectable NFTs into the Explorer Mine system. They can withdraw earned STRDUST rewards to win NFT rewards in auctions or to use in The Open Space game. For detailed information, you can review The Open Space Whitepaper page.',
      image: "QmZz3sHNTqi7nJEjUNPQMv5Rxc55KeEc8rXzqSF5GSa72L",
      button: { text: 'Learn More', link: '/theopenspace/exploration' },
    },
    
  ];

  const handleButtonClick = (link) => {
    if (link.startsWith('http') || link.startsWith('https')) {
      window.open(link, '_blank', 'noopener noreferrer');
    } else {
      window.location.href = link;
    }
  };

  return (
    <div className="explorermine-page">
      <header className="explorermine-header">
        <div className="explorermine-header-content">
          <img src={getIpfsImageUrl(logoImage)} alt="Explorer Mine Logo" className="game-logo" />
          <div className="explorermine-title">
            <h1>Explorer Mine</h1>
            <p>Participants use their NFTs to earn tokens from reward pools and join auctions to win NFT rewards.</p>
          </div>
        </div>
      </header>

      <div className="explorermine-actions">
        {buttons.map((button, index) => (
          <button
            key={index}
            className="explorermine-btn"
            onClick={() => handleButtonClick(button.link)}
          >
            {button.name}
          </button>
        ))}
      </div>

      <section className="explorermine-about-sections">
        {aboutSections.map((section, index) => (
          <div key={index} className="explorermine-card">
            <img src={getIpfsBanner(section.image)} alt={section.title} className="explorermine-card-img" />
            <div className="explorermine-card-body">
              <h5 className="explorermine-card-title">{section.title}</h5>
              <p className="explorermine-card-text">{section.description}</p>
              {section.button && (
                <button
                  className="explorermine-btn"
                  onClick={() => handleButtonClick(section.button.link)}
                >
                  {section.button.text}
                </button>
              )}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}

export default Explorermine;
