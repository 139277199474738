import React, { useState, useEffect } from 'react';
import './DepositModal.css'; // Modal için CSS
import { getUserTokens } from './contractService'; // Kullanıcı token bilgilerini almak için

const DepositModal = ({ show, onClose, wallet, handleDepositConfirm }) => {
  const [depositAmount, setDepositAmount] = useState(''); // Yatırılacak miktarı kontrol ediyoruz
  const [tokenSymbol, setTokenSymbol] = useState(''); // Seçili token sembolü
  const [userTokens, setUserTokens] = useState([]); // Kullanıcı token bilgilerini tutuyoruz
  const [tokenPrecision, setTokenPrecision] = useState(0); // Seçili token'ın hassasiyetini tutuyoruz
  const [selectedTokenBalance, setSelectedTokenBalance] = useState(''); // Seçili token bakiyesi

  // Modal açıldığında kullanıcının token bilgilerini çekiyoruz ve state'leri sıfırlıyoruz
  useEffect(() => {
    const fetchUserTokens = async () => {
      if (wallet && show) {
        const tokens = await getUserTokens(wallet);
        setUserTokens(tokens); // Kullanıcı token bilgilerini state'e kaydediyoruz
      }
    };

    if (show) {
      // Modal açıldığında seçim ve amount sıfırlanır
      setTokenSymbol('');
      setDepositAmount('');
      setSelectedTokenBalance(''); // Token bakiyesi sıfırlanır
      fetchUserTokens();
    }
  }, [wallet, show]);

  // Token türü değişikliği işlemi
  const handleTokenSymbolChange = (e) => {
    const selectedTokenSymbol = e.target.value;
    const selectedToken = userTokens.find(token => token.symbol === selectedTokenSymbol);

    if (selectedToken) {
      setTokenPrecision(selectedToken.precision); // Seçilen token'ın hassasiyetini kaydediyoruz
      setSelectedTokenBalance(selectedToken.balance); // Seçilen token'ın bakiyesini kaydediyoruz
    }

    setTokenSymbol(selectedTokenSymbol);
  };

  // Miktarın hassasiyetine göre formatlama işlemi
  const formatAmount = (amount, precision) => {
    if (!amount.includes('.')) {
      // Nokta yoksa, noktadan sonra hassasiyet kadar sıfır ekle
      return amount + '.' + '0'.repeat(precision);
    } else {
      const [integerPart, decimalPart] = amount.split('.');
      if (decimalPart.length < precision) {
        // Eğer nokta sonrası rakam sayısı hassasiyetten küçükse, sıfır ekle
        return integerPart + '.' + decimalPart + '0'.repeat(precision - decimalPart.length);
      } else if (decimalPart.length > precision) {
        // Eğer nokta sonrası rakam sayısı hassasiyetten büyükse, fazlalığı sil
        return integerPart + '.' + decimalPart.substring(0, precision);
      } else {
        return amount; // Tam olarak hassasiyete uygun
      }
    }
  };

  // İşlem başlatma fonksiyonu
  const handleConfirm = async () => {
    if (tokenSymbol) {
      const formattedAmount = formatAmount(depositAmount, tokenPrecision); // Miktarı formatla
      const selectedToken = userTokens.find(token => token.symbol === tokenSymbol); // Seçili token'ı bul
      if (selectedToken) {
        await handleDepositConfirm(formattedAmount + " " + tokenSymbol, selectedToken.contract); // Kontrat adresi ile onayla
      }
    }
  };

  // Input değişikliği işlemi, özel semboller ve birden fazla nokta eklenmesini engelliyoruz
  const handleDepositAmountChange = (e) => {
    let value = e.target.value;

    // Özel karakterleri engelle: sadece rakamlar ve tek bir nokta izinli
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(value)) {
      // Birden fazla nokta olup olmadığını kontrol et
      const dotCount = (value.match(/\./g) || []).length;

      if (dotCount <= 1) {
        setDepositAmount(value);
      }
    }
  };

  // Gösterim kapalıysa null dön
  if (!show) return null;

  return (
    <div className="depositmodal-overlay">
      <div className="depositmodal-container">
        <div className="depositmodal-header">
          <h3>Deposit</h3>
          <i className="fas fa-times close-icon" onClick={onClose}></i>
        </div>
        <div className="depositmodal-body">
          {tokenSymbol && (
            <p className="balance-info"><strong>Balance: </strong>{selectedTokenBalance}</p>
          )}
          <label htmlFor="token-symbol">Token:</label>
          <select
            id="token-symbol"
            value={tokenSymbol}
            onChange={handleTokenSymbolChange}
            placeholder="Select token"
          >
            <option value="" disabled>Select token</option>
            {userTokens.map((token, index) => (
              <option key={index} value={token.symbol}>
                {token.contract} - {token.symbol}
              </option>
            ))}
          </select>

          

          <label htmlFor="deposit-amount">Amount to Deposit:</label>
          <input
            type="text"
            id="deposit-amount"
            value={depositAmount}
            onChange={handleDepositAmountChange}
            placeholder="Enter amount"
          />
        </div>
        <div className="depositmodal-footer">
          <button
            className="confirm-button"
            onClick={handleConfirm}
            disabled={!depositAmount || !tokenSymbol}>
            Confirm Deposit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DepositModal;
