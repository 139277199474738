// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marketplace-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.marketplace-title {
  color: #00bcd4;
  font-size: 2.5rem;
  margin: 0;
}

.collection-selection {
  display: flex;
  justify-content: center; /* Butonları ortalar */
  flex-wrap: wrap; /* Satırdan taşan butonları alt satıra atar */
  align-items: center;
  margin-bottom: 10px;
}

.collection-selection label {
  margin-right: 10px;
  margin-bottom: 10px; /* Alt satıra atıldığında butonlar arasında boşluk sağlar */
  font-size: 1rem;
  color: #dcdcdc;
}

.collection-selection input[type="radio"] {
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
}

.marketplace-description {
  font-size: 1.2rem;
  color: #dcdcdc;
  margin-bottom: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Marketplace/Marketplace.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB,EAAE,sBAAsB;EAC/C,eAAe,EAAE,6CAA6C;EAC9D,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB,EAAE,2DAA2D;EAChF,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".marketplace-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 30px;\n}\n\n.marketplace-title {\n  color: #00bcd4;\n  font-size: 2.5rem;\n  margin: 0;\n}\n\n.collection-selection {\n  display: flex;\n  justify-content: center; /* Butonları ortalar */\n  flex-wrap: wrap; /* Satırdan taşan butonları alt satıra atar */\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.collection-selection label {\n  margin-right: 10px;\n  margin-bottom: 10px; /* Alt satıra atıldığında butonlar arasında boşluk sağlar */\n  font-size: 1rem;\n  color: #dcdcdc;\n}\n\n.collection-selection input[type=\"radio\"] {\n  justify-content: center;\n  margin-right: 10px;\n  margin-left: 10px;\n}\n\n.marketplace-description {\n  font-size: 1.2rem;\n  color: #dcdcdc;\n  margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
