// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-section {
    margin-bottom: 40px; /* Her bölümün altına 40px boşluk ekler */
  }
  
  .home-section:last-child {
    margin-bottom: 0; /* Son bölümde boşluk bırakmaz */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB,EAAE,yCAAyC;EAChE;;EAEA;IACE,gBAAgB,EAAE,gCAAgC;EACpD","sourcesContent":[".home-section {\n    margin-bottom: 40px; /* Her bölümün altına 40px boşluk ekler */\n  }\n  \n  .home-section:last-child {\n    margin-bottom: 0; /* Son bölümde boşluk bırakmaz */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
