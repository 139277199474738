// src/services/IpfsService.js

export const getIpfsImageUrl = (cid) => {
  return `https://resizer2.neftyblocks.com/?ipfs=${cid}&width=300&static=false`;
};

export const getIpfsBanner = (cid) => {
  return `https://ipfs.neftyblocks.io/ipfs/${cid}`;
};

export const getIpfsVideoUrl = (cid) => {
  return `https://ipfs.neftyblocks.io/ipfs/${cid}`;
};
  