import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-us-container">
      <h2 className="about-us-title">About Me</h2>
      <p className="about-us-description">
        Hello! I am Mehmet, an independent game developer passionate about creating immersive and engaging gaming experiences. With a deep understanding of game design and a dedication to pushing creative boundaries, I work tirelessly to bring unique and compelling games to life.
        <br/><br/>
        In addition to game development, I am highly invested in the potential of blockchain technology. I believe that blockchain can revolutionize the gaming industry by providing true ownership of digital assets, enhancing security, and enabling decentralized game economies. By integrating blockchain into my projects, I aim to create transparent, fair, and innovative gaming experiences that empower players and redefine the future of gaming.
      </p>
      <div className="vision-section">
        <h3 className="vision-title">My Vision</h3>
        <p className="vision-text">
          My goal is to develop games that not only entertain but also inspire. I believe that games have the power to tell impactful stories, connect people, and drive creativity. I am committed to innovation and excellence in every project, constantly striving to improve and deliver exceptional experiences.
        </p>
      </div>
      <div className="about-us-footer">
        <p className="footer-text">
          Thank you for joining me on this journey. Stay tuned for exciting updates and new projects!
        </p>
      </div>
    </div>
  );
}

export default About;
