import React, { useState, useEffect } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import './TheopenspaceGame.css';

function TheopenspaceGame() {
  const { unityProvider } = useUnityContext({
    loaderUrl: "/build/Build.loader.js",
    dataUrl: "/build/Build.data.unityweb",
    frameworkUrl: "/build/Build.framework.js.unityweb",
    codeUrl: "/build/Build.wasm.unityweb",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
    devicePixelRatio: window.devicePixelRatio,
  });

  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  // Tam ekran yapma fonksiyonu
  const handleFullscreen = () => {
    const canvas = document.querySelector("canvas");
    if (canvas.requestFullscreen) {
      canvas.requestFullscreen();
    } else if (canvas.mozRequestFullScreen) { // Firefox
      canvas.mozRequestFullScreen();
    } else if (canvas.webkitRequestFullscreen) { // Chrome, Safari, Opera
      canvas.webkitRequestFullscreen();
    } else if (canvas.msRequestFullscreen) { // IE/Edge
      canvas.msRequestFullscreen();
    }
  };

  // Mobil cihazlarda ekran yönünü kontrol etme
  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", checkOrientation);
    window.addEventListener("load", checkOrientation);

    return () => {
      window.removeEventListener("resize", checkOrientation);
      window.removeEventListener("load", checkOrientation);
    };
  }, []);

  return (
    <div>
      <div className="warning-banner">
        This page is currently under development!
      </div>
      <div className="theopenspace-game-container">
        <h1>The Open Space Game</h1>
        <div className="webgl-game">
          <Unity unityProvider={unityProvider} />
          {/* Tam Ekran Butonu */}
          <button onClick={handleFullscreen} className="fullscreen-button">Full Screen [ ]</button>
        </div>
      </div>

      {/* Ekran Dikey Olduğunda Gösterilecek Mesaj */}
      {isPortrait && (
        <div className="orientation-warning">
          <p>Please turn your device to landscape mode ⟳</p>
        </div>
      )}
    </div>
  );
}

export default TheopenspaceGame;
