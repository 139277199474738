// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.App {
  min-height: 100vh; /* Uygulama yüksekliği */
  background-color: #000000; /* Genel arka plan rengi */
  color: #cdd3d9;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,iBAAiB,EAAE,wBAAwB;EAC3C,yBAAyB,EAAE,0BAA0B;EACrD,cAAc;AAChB","sourcesContent":["\n.App {\n  min-height: 100vh; /* Uygulama yüksekliği */\n  background-color: #000000; /* Genel arka plan rengi */\n  color: #cdd3d9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
