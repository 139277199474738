// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supported-platforms-section {
  background-color: #22252A;
  padding: 60px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 60px;
  text-align: center;
}

.supported-platforms-section h2 {
  font-size: 2.5em;
  color: #4be7ff;
  margin-bottom: 50px;
  text-transform: none;
}

.platforms-list {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.platforms-list a {
  text-decoration: none;
  color: #ffffff;
  transition: transform 0.3s ease, color 0.3s ease;
  text-align: center;
}

.platforms-list a:hover {
  transform: scale(1.05);
  color: #8df0ff;
}

.platforms-list img {
  max-height: 60px;
  filter: brightness(0) invert(1);
  margin-bottom: 10px;
}

.platforms-list p {
  margin-top: 0;
  font-size: 1.2em;
  text-transform: capitalize;
}

.platforms-list small {
  display: block;
  font-size: 0.8em;
  color: #888888;
}
`, "",{"version":3,"sources":["webpack://./src/components/SupportedPlatforms/SupportedPlatforms.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,yCAAyC;EACzC,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,gDAAgD;EAChD,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".supported-platforms-section {\n  background-color: #22252A;\n  padding: 60px 20px;\n  border-radius: 10px;\n  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);\n  margin-bottom: 60px;\n  text-align: center;\n}\n\n.supported-platforms-section h2 {\n  font-size: 2.5em;\n  color: #4be7ff;\n  margin-bottom: 50px;\n  text-transform: none;\n}\n\n.platforms-list {\n  display: flex;\n  justify-content: center;\n  gap: 40px;\n  flex-wrap: wrap;\n}\n\n.platforms-list a {\n  text-decoration: none;\n  color: #ffffff;\n  transition: transform 0.3s ease, color 0.3s ease;\n  text-align: center;\n}\n\n.platforms-list a:hover {\n  transform: scale(1.05);\n  color: #8df0ff;\n}\n\n.platforms-list img {\n  max-height: 60px;\n  filter: brightness(0) invert(1);\n  margin-bottom: 10px;\n}\n\n.platforms-list p {\n  margin-top: 0;\n  font-size: 1.2em;\n  text-transform: capitalize;\n}\n\n.platforms-list small {\n  display: block;\n  font-size: 0.8em;\n  color: #888888;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
