import React from 'react';
import './Features.css';

function FeaturesSection() {
  return (
    <section className="features-section text-center">
      <h2>Our Technology and Features</h2>
      <div className="features-list">
        <div className="feature-item">
          <i className="fas fa-coins"></i>
          <h3>Blockchain Integration</h3>
          <p>Seamless integration of blockchain technology into our games.</p>
        </div>
        <div className="feature-item">
          <i className="fas fa-gem"></i>
          <h3>NFT Assets</h3>
          <p>Own, trade, and sell unique in-game assets as NFTs.</p>
        </div>
        <div className="feature-item">
          <i className="fas fa-users"></i>
          <h3>Multiplayer Universe</h3>
          <p>Interact with thousands of players in a shared, decentralized world.</p>
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;
