import React, { useState, useEffect } from 'react';
import './Inventory.css'; // Ortak CSS dosyasını import ediyoruz
import { fetchUserInventory, fetchUserCompNFT } from '../contractService';
import { getIpfsImageUrl } from '../../../../components/IpfsService'; // NFT'lerin resimlerini almak için
import StakeModal from '../StakeModal'; // StakeModal bileşenini import ediyoruz

const Inventory = ({ wallet, selectedWallet, nftConfigs, navigateToSection, unstakeAct, refresh, stakeAct, completeNftAct, userData }) => {
  const [inventoryItems, setInventoryItems] = useState([]);
  const [tempItems, setTempItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]); // Filtrelenmiş NFT'leri göstermek için
  const [owner, setOwner] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showStakeModal, setShowStakeModal] = useState(false); // Modal için state

  // Filtreleme State'leri
  const [collectionFilter, setCollectionFilter] = useState('');
  const [schemaFilter, setSchemaFilter] = useState('');
  const [templateIdFilter, setTemplateIdFilter] = useState('');

  useEffect(() => {
    if (wallet && wallet === selectedWallet) {
      setOwner(true);
    } else {
      setOwner(false);
    }
    setSelectedItems([]);
  }, [wallet, selectedWallet]);

  useEffect(() => {
    if (selectedWallet) {
      const getUserInventory = async () => {
        try {
          const inventory = await fetchUserInventory(selectedWallet);
          setInventoryItems(inventory);
          setFilteredItems(inventory); // İlk başta tüm NFT'leri göster

        } catch (error) {
          console.log(error);
        }
      };
      getUserInventory();
    } else {
      setInventoryItems([]); // Inventory boşsa
      setFilteredItems([]);
    }
    setSelectedItems([]);
  }, [selectedWallet, refresh]);

  useEffect(() => {
    if (owner) {
      const getUserTempNfts = async () => {
        try {
          const temps = await fetchUserCompNFT(selectedWallet);
          setTempItems(temps);
        } catch (error) {
          console.log(error);
        }
      };
      getUserTempNfts();
    }
  }, [owner, selectedWallet, refresh]);

  // Template ID'ye göre NFT ayrıntılarını bulur
  const getNftDetails = (templateId) => {
    if (!nftConfigs) return { mediaUrl: 'https://cdn.nft.io/images/common/default-collection.webp', name: 'Unknown NFT', templateId, collection: 'Unknown', mediaType: 'image' };
    
    const nftConfig = nftConfigs.find(config => config.template_id === templateId);

    if (nftConfig) {
      return {
        mediaUrl: nftConfig.img ? getIpfsImageUrl(nftConfig.img) : getIpfsImageUrl(nftConfig.video), // Eğer img varsa onu, yoksa video'yu al
        mediaType: nftConfig.img ? 'image' : 'video', // Media tipini kontrol etmek için
        name: nftConfig.name_,
        collection: nftConfig.collection,
        templateId: nftConfig.template_id,
        schema: nftConfig.schema,
        category: nftConfig.category,
        nft_weight: nftConfig.nft_weight,
      };
    } else {
      return {
        mediaUrl: 'https://cdn.nft.io/images/common/default-collection.webp',
        name: 'Unknown NFT',
        templateId,
        collection: 'Unknown',
        mediaType: 'image',
      };
    }
  };

  // Checkbox seçimi işlemi
  const handleSelectItem = (assetId) => {
    if (selectedItems.includes(assetId)) {
      setSelectedItems(selectedItems.filter(id => id !== assetId));
    } else {
      setSelectedItems([...selectedItems, assetId]);
    }
  };

  // Filtreleme işlemi
  const handleFilterChange = (collection, schema, templateId) => {
    let filtered = inventoryItems;

    // Eğer collection filter doluysa bunu uygular
    if (collection) {
      filtered = filtered.filter(item => {
        const nftDetails = getNftDetails(item.template_id);
        return nftDetails.collection.toLowerCase().includes(collection.toLowerCase());
      });
    }

    // Eğer schema filter doluysa bunu uygular
    if (schema) {
      filtered = filtered.filter(item => {
        const nftDetails = getNftDetails(item.template_id);
        return nftDetails.schema.toLowerCase().includes(schema.toLowerCase());
      });
    }

    // Eğer templateId filter doluysa bunu uygular
    if (templateId) {
      filtered = filtered.filter(item => {
        const nftDetails = getNftDetails(item.template_id);
        return nftDetails.templateId === parseInt(templateId);
      });
    }

    setFilteredItems(filtered);
  };

  // Filtreleri temizleme işlemi
  const handleClearFilters = () => {
    setCollectionFilter('');
    setSchemaFilter('');
    setTemplateIdFilter('');
    setFilteredItems(inventoryItems); // Tüm NFT'leri geri getir
  };

  // Stake butonuna tıklama işlemi
  const handleStake = () => {
    console.log('Stake işlemi yapıldı:', selectedItems);
    setShowStakeModal(true); // Modal açılıyor
  };

  // Unstake butonuna tıklama işlemi
  const handleUnstake = () => {
    console.log('Unstake işlemi yapıldı:', selectedItems);
    unstakeAct(selectedItems);
  };

  const handleSend = async(assets) => {
    console.log('stake işlemi yapıldı:', assets);
    stakeAct(assets); 
  };

  // Stake'i tamamla butonuna tıklandığında log yazdırma
  const handleCompleteStake = () => {
    console.log('Stake işlemi tamamlanıyor...');
    completeNftAct();
  };

  return (
    <div className="inv">
      <h2>Inventory</h2>

      {owner && !userData && (
        <p>You need to be registered to perform any actions.</p>
      )}

      {owner && userData && tempItems.length > 0 && (
        <div className="stake-warning">
          <p>You have {tempItems.length} incomplete stake transaction(s).</p>
          <button onClick={handleCompleteStake} className="complete-stake-button">
            Complete Stake
          </button>
        </div>
      )}
      <div className="inventory-wallet-info">
        <p><strong>Wallet:</strong> {selectedWallet}</p>
      </div>

      <div className="inventory-actions">
        {owner && userData && (
          <>
            <button 
              onClick={handleUnstake} 
              disabled={selectedItems.length === 0} // Eğer seçili NFT yoksa butonu pasif yapıyoruz
              className={selectedItems.length === 0 ? 'disabled-button' : ''} // Eğer seçili yoksa pasif stil uyguluyoruz
            >
              Unstake
            </button>
            <button onClick={handleStake}>Stake</button>
          </>
        )}
        
        {/* Filtreleme Alanı */}
        <div className="filter-section">
          <input
            type="text"
            placeholder="Collection Name"
            value={collectionFilter}
            onChange={(e) => {
              setCollectionFilter(e.target.value);
              handleFilterChange(e.target.value, schemaFilter, templateIdFilter);
            }}
          />

          <input
            type="text"
            placeholder="Schema Name"
            value={schemaFilter}
            onChange={(e) => {
              setSchemaFilter(e.target.value);
              handleFilterChange(collectionFilter, e.target.value, templateIdFilter);
            }}
          />

          <input
            type="number"
            placeholder="Template ID"
            value={templateIdFilter}
            onChange={(e) => {
              setTemplateIdFilter(e.target.value);
              handleFilterChange(collectionFilter, schemaFilter, e.target.value);
            }}
          />

          {/* Eğer filtreleme yapılıyorsa "Clear Filters" butonu göster */}
          {(collectionFilter || schemaFilter || templateIdFilter) && (
            <button onClick={handleClearFilters} className="clear-button">Clear</button>
          )}
        </div>
      </div>

      {filteredItems.length > 0 ? (
        <div className="inventory-items">
          {filteredItems.map((item, index) => {
            const nftDetails = getNftDetails(item.template_id);
            const isSelected = selectedItems.includes(item.asset_id);
            return (
              <div 
                key={index} 
                className={`nft-card ${isSelected ? 'selected' : ''}`} 
                onClick={owner ? () => handleSelectItem(item.asset_id) : null} // Sadece owner true ise tıklanabilir
              >
                <div className="category-badge"># {nftDetails.templateId}</div> 
                {owner && ( // Checkbox sadece owner true ise görünür
                  <input 
                    type="checkbox" 
                    checked={isSelected} 
                    onChange={() => handleSelectItem(item.asset_id)} 
                    className="nft-checkbox" 
                  />
                )}
                {nftDetails.mediaType === 'image' ? (
                  <img 
                    src={nftDetails.mediaUrl} 
                    alt={nftDetails.name} 
                    className="inventory-media"
                  />
                ) : (
                  <video 
                    src={nftDetails.mediaUrl}                     
                    className="inventory-media"
                    autoPlay
                    muted
                    loop
                  />
                )}
                <div className="nft-info">
                  <h5>{nftDetails.name}</h5>
                  <p><strong>ID:</strong> {item.asset_id}</p>
                  <p><strong>Collection:</strong> {nftDetails.collection}</p>
                  <p><strong>Schema:</strong> {nftDetails.schema}</p>                                    
                  <p><strong>Category:</strong> {nftDetails.category}</p>
                  <p><strong>Weight:</strong> {nftDetails.nft_weight}</p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="inventory-actions">
          <p>No Staked NFT in inventory</p>
        </div>
      )}

      {/* Stake Modal */}
      <StakeModal 
        show={showStakeModal} 
        onClose={() => setShowStakeModal(false)} 
        wallet={wallet}
        handleSend={handleSend}
      />
    </div>
  );
};

export default Inventory;
