import React from 'react';
import './SupportedPlatforms.css';
import atomichubLogo from '../../assets/logo/atomichub_logo.png';
import neftyLogo from '../../assets/logo/nefty_logo.png';
import nfthiveLogo from '../../assets/logo/nfthive_logo.png';
import alcorLogo from '../../assets/logo/alcor_logo.png';
import tacoLogo from '../../assets/logo/taco_logo.png';
import mycloudLogo from '../../assets/logo/mycloud_logo.png';
import anchorLogo from '../../assets/logo/anchor_logo.png';
import playfabLogo from '../../assets/logo/playfab_logo.png';
import digitaloceanLogo from '../../assets/logo/digitalocean_logo.png';
import wax_logoLogo from '../../assets/logo/wax_logo.png';
import eos_logoLogo from '../../assets/logo/eos_logo.png';
import eosio_logoLogo from '../../assets/logo/eosio_logo.png';

const platforms = [  
  { name: 'WAX Chain', logo: wax_logoLogo, link: 'https://www.wax.io/', type: 'Blockchain' },
  { name: 'EOS Chain', logo: eos_logoLogo, link: 'https://eosnetwork.com/', type: 'Blockchain' },
  { name: 'MyCloud Wallet', logo: mycloudLogo, link: 'https://www.mycloudwallet.com/', type: 'Wallet' },
  { name: 'Anchor Wallet', logo: anchorLogo, link: 'https://www.greymass.com/anchor', type: 'Wallet' },
  { name: 'Atomichub', logo: atomichubLogo, link: 'https://atomichub.io', type: 'Marketplace' },
  { name: 'NeftyBlocks', logo: neftyLogo, link: 'https://neftyblocks.com/', type: 'Marketplace' },
  { name: 'NFT Hive', logo: nfthiveLogo, link: 'https://nfthive.io/', type: 'Marketplace' },
  { name: 'Alcor Exchange', logo: alcorLogo, link: 'https://alcor.exchange', type: 'Exchange' },
  { name: 'Taco Swap', logo: tacoLogo, link: 'https://swap.tacocrypto.io/', type: 'Exchange' },
  { name: 'EOS Studio', logo: eosio_logoLogo, link: 'https://docs.eosstudio.io/index.html', type: 'Service' },
  { name: 'PlayFab', logo: playfabLogo, link: 'https://playfab.com/', type: 'Service' },
  { name: 'DigitalOcean', logo: digitaloceanLogo, link: 'https://www.digitalocean.com/', type: 'Service' }, 
];

function SupportedPlatforms() {
  return (
    <section className="supported-platforms-section text-center">
      <h2>Supported Ecosystem</h2>
      <div className="platforms-list">
        {platforms.map((platform, index) => (
          <a key={index} href={platform.link} target="_blank" rel="noopener noreferrer">
            <img src={platform.logo} alt={platform.name} />
            <p>{platform.name}</p>
            <small>{platform.type}</small> {/* Platform türünü göstermek için */}
          </a>
        ))}
      </div>
    </section>
  );
}

export default SupportedPlatforms;
