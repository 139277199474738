import React, { useState, useEffect } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import './TheopenspaceGame.css';
import logo from "../../../assets/images/logo.png";

import AnchorLink from "anchor-link";
import AnchorLinkBrowserTransport from "anchor-link-browser-transport";
import * as waxjs from "@waxio/waxjs/dist";


let walletAddress = null;
let identity = null;
const apiEndpoints = ["https://wax.greymass.com"];
const testapiEndpoints = ["https://waxtestnet.greymass.com"];
const main_account = "theopenspace"; 
const mainnet_address = "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4";
const testnet_address = "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12";

const transport = new AnchorLinkBrowserTransport();
const link = new AnchorLink({
  transport,
  chains: [
    {
      chainId: mainnet_address,
      nodeUrl: getRandomApiEndpoint(),
    },
    {
      chainId: testnet_address,
      nodeUrl: getRandomTestApiEndpoint(),
    },
  ],
});

function getRandomApiEndpoint() {
  const randomIndex = Math.floor(Math.random() * apiEndpoints.length);
  return apiEndpoints[randomIndex];
}

function getRandomTestApiEndpoint() {
  const randomTestIndex = Math.floor(Math.random() * testapiEndpoints.length);
  return testapiEndpoints[randomTestIndex];
}
function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function TheopenspaceGame() {
  const { unityProvider, loadingProgression, isLoaded, addEventListener,removeEventListener, sendMessage  } = useUnityContext({
    loaderUrl: "/build/Build.loader.js",
    dataUrl: "/build/Build.data.unityweb",
    frameworkUrl: "/build/Build.framework.js.unityweb",
    codeUrl: "/build/Build.wasm.unityweb",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
    devicePixelRatio: window.devicePixelRatio,
  });

  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [progress, setProgress] = useState(0);
  const [wallet, setWallet] = useState(null);
  const [logintype, setLoginType] = useState(null);

  let wax = new waxjs.WaxJS({
    rpcEndpoint: getRandomTestApiEndpoint(), //getRandomApiEndpoint(),
    tryAutoLogin: false
  });

  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", checkOrientation);
    window.addEventListener("load", checkOrientation);

    return () => {
      window.removeEventListener("resize", checkOrientation);
      window.removeEventListener("load", checkOrientation);
    };
  }, []);

  // loadingProgression değeri değiştiğinde progress durumunu güncelle
  useEffect(() => {
    setProgress(loadingProgression);
  }, [loadingProgression]);

  const handleFullscreen = () => {
    const canvas = document.querySelector("canvas");
    if (canvas.requestFullscreen) {
      canvas.requestFullscreen();
    } else if (canvas.mozRequestFullScreen) { // Firefox
      canvas.mozRequestFullScreen();
    } else if (canvas.webkitRequestFullscreen) { // Chrome, Safari, Opera
      canvas.webkitRequestFullscreen();
    } else if (canvas.msRequestFullscreen) { // IE/Edge
      canvas.msRequestFullscreen();
    }
  };


  // Unity RECEIVE
  useEffect(() => {
    // GameStart olayını dinle
    const handleGameStart = (event) => {
      console.log("GameStart event received from Unity:", event.detail);
      // Burada gerekli işlemleri yapabilirsiniz
    };

    // Login olayını dinle
    const handleLogin = async (index) => {
      let connectedWallet = null;
      let loginType = null;
      if (index === 1) {
        try {
          await wax.login();
          walletAddress = wax.userAccount;
          setWallet(wax.userAccount);
          loginType = 1;
          connectedWallet = wax.userAccount;
        } catch (error) {
          console.error('Cloud Wallet login failed', error);
          sendMessageToUnity("Network Manager", "HandleLoginError", JSON.stringify({ error}))
        }
      } else if (index === 2) {
        try {
          identity = await link.login("explorermine");
          let { session } = identity;
          const toSimpleObject = (v) => JSON.parse(JSON.stringify(v));
          walletAddress = toSimpleObject(session.auth.actor);
          setWallet(toSimpleObject(session.auth.actor));
          connectedWallet = session.auth.actor;
          if(toSimpleObject(session.chainId.chainName) === 10){
            loginType = 2; //mainnet
          }
          else{
            loginType = 3; //testnet
          }        
        } catch (error) {
          console.error('Anchor Wallet login failed', error);
          sendMessageToUnity("Network Manager", "HandleLoginError", JSON.stringify({ error}))
        }
      }

      if(connectedWallet){
        setWallet(connectedWallet);
        setLoginType(loginType);
        sendMessageToUnity("Network Manager", "HandleUserData", JSON.stringify({ wallet: connectedWallet, logintype: loginType }))
        //sendMessage("Network Manager", "HandleUserData", JSON.stringify({ wallet: connectedWallet, logintype: loginType }));
      }
      else{
        sendMessageToUnity("Network Manager", "HandleLoginError", JSON.stringify({ msg: "Connection Failed."}))
      }
    };

    // Register olayını dinle
    const handleRegister = (event) => {
      console.log("Register event received from Unity:", event.detail);
      // Burada gerekli işlemleri yapabilirsiniz
    };

    // unityContext kullanımı
    addEventListener("GameStart", handleGameStart);
    addEventListener("Login", handleLogin);
    addEventListener("Register", handleRegister);

    return () => {
      removeEventListener("GameStart", handleGameStart);
      removeEventListener("Login", handleLogin);
      removeEventListener("Register", handleRegister);
    };
  }, [addEventListener, removeEventListener, isLoaded]);

  // Unity SEND
  const sendMessageToUnity = (objName, funcName, data) => {
    if (isLoaded) {
      sendMessage(objName, funcName, data);
  } else {
      console.warn("Unity henüz yüklenmedi, mesaj gönderilemedi.");
  }
  };

  return (
    <div>
      <div className="warning-banner">
        This page is currently under development!
      </div>
      <div className="theopenspace-game-container">
        <h1>The Open Space Game</h1>
        <div className="webgl-game">
          <div className={`loading-container ${isLoaded ? "hidden" : ""}`}>
            <div className="image-container">
              <img src={logo} alt="Logo" className="loading-logo"/>
            </div>
            <div className="outerBar">
              <div className="innerBar" style={{ width: `${progress * 100}%` }}></div>
            </div>
            <p className="loading-text">
              The Open Space v4.0 Loading... {Math.round(progress * 100)}%
            </p>
          </div>
          <Unity style={{ width: "100%", height: "100%" }} unityProvider={unityProvider} />
          <button onClick={handleFullscreen} className="fullscreen-button">Full Screen [ ]</button>
        </div>
      </div>

      {isPortrait && (
        <div className="orientation-warning">
          <p>Please turn your device to landscape mode ⟳</p>
        </div>
      )}
    </div>
  );
}

export default TheopenspaceGame;
