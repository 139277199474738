import React, { useState, useEffect } from 'react';
import './Pools.css'; 
import { fetchPools, fetchNftConfigs } from '../contractService';
import { getIpfsImageUrl } from '../../../../components/IpfsService';

function Pools() {
  const [nftConfigs, setNftConfigs] = useState([]); 
  const [pools, setPools] = useState([]); 
  const [selectedCategory, setSelectedCategory] = useState('All'); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const poolData = await fetchPools();
        setPools(poolData);

        const nftData = await fetchNftConfigs();
        setNftConfigs(nftData);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }, []);

  const getNftsByCategory = (category) => {
    return category === 'All'
      ? nftConfigs
      : nftConfigs.filter((nft) => nft.category === parseInt(category));
  };

  const handleNftClick = (template_id) => {
    const url = `https://atomichub.io/explorer/template/wax-mainnet/${template_id}`;
    window.open(url, '_blank');
  };

  return (
    <div className="pools">
      <h2>Pools</h2>
      <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
        <option value="All">All Categories</option>
        {pools.map((pool, index) => (
          <option key={index} value={pool.category}>Category {pool.category}</option>
        ))}
      </select>
      <div className="pool-container">
        {pools
          .filter(pool => selectedCategory === 'All' || pool.category === parseInt(selectedCategory))
          .map((pool, index) => (
            <div className="pool-category-container" key={index}>
              <h2 className="pool-category-title">
                <p><strong>Category: </strong>{pool.category}</p>
                <p><strong>Total Weight: </strong>{pool.pool_weight}</p>
                <p><strong>Reward: </strong>{pool.reward}</p>
              </h2>
              <div className="nft-container">
                {getNftsByCategory(pool.category).length > 0 ? (
                  getNftsByCategory(pool.category).map((nft, nftIndex) => (
                    <div
                      className="nft-card"
                      key={nftIndex}
                      onClick={() => handleNftClick(nft.template_id)} 
                      style={{ cursor: 'pointer' }} 
                    >
                      <div className="nft-badge"># {nft.template_id}</div>
                      
                      {/* Resim veya video kontrolü */}
                      {nft.img ? (
                        <img
                          src={getIpfsImageUrl(nft.img)}
                          alt={nft.name_}
                          className="pool-media"
                        />
                      ) : (
                        <video
                          src={getIpfsImageUrl(nft.video)}
                          className="pool-media"
                          autoPlay
                          muted
                          loop
                        />
                      )}
                      
                      <div className="nft-info">
                        <h5>{nft.name_}</h5>
                        <p><strong>Collection:</strong> {nft.collection}</p>
                        <p><strong>Schema:</strong> {nft.schema}</p>
                        <p><strong>Weight:</strong> {nft.nft_weight}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No NFTs available in this category</p>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Pools;
