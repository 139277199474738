import React, { useState, useEffect } from 'react';
import './StakeModal.css'; // Modal için gerekli stiller
import { fetchUserNfts, fetchNftConfigs } from './contractService';
import { getIpfsImageUrl } from '../../../components/IpfsService'; // NFT'lerin resimlerini almak için

const StakeModal = ({ show, onClose, wallet , handleSend}) => {
  const [nftData, setNftData] = useState([]);
  const [selectedNFTs, setSelectedNFTs] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(true); // Yükleme durumu için state
  const [imageLoading, setImageLoading] = useState({}); // Resim yüklenme durumu için state

  // Filtreleme State'leri
  const [collectionFilter, setCollectionFilter] = useState('');
  const [schemaFilter, setSchemaFilter] = useState('');
  const [templateIdFilter, setTemplateIdFilter] = useState('');

  useEffect(() => {
    if (show && wallet) {
      const loadNfts = async () => {
        setLoading(true); // Yükleme başlatılıyor
        try {
          const nftConfigs = await fetchNftConfigs();
          const userNfts = await fetchUserNfts(wallet);

          const matchedNfts = userNfts
            .map((nft) => {
              const config = nftConfigs.find(c => c.template_id === nft.template_id);
              return config
                ? {
                    asset_id: nft.asset_id,
                    media: config.img ? getIpfsImageUrl(config.img) : getIpfsImageUrl(config.video), // Eğer img varsa onu, yoksa video'yu al
                    mediaType: config.img ? 'image' : 'video', // Media tipini kontrol etmek için
                    ...config 
                  }
                : null;
            })
            .filter(nft => nft !== null);

          setNftData(matchedNfts);
          setFilteredItems(matchedNfts);
        } catch (error) {
          console.error('Error loading NFTs:', error);
        } finally {
          setLoading(false); 
        }
      };

      loadNfts();
    }

    // Modal her açıldığında seçili NFT'leri sıfırla
    return () => setSelectedNFTs([]);
  }, [show, wallet]);

  // Tüm NFT'leri seç
  const handleSelectAll = () => {
    if (filteredItems.length === selectedNFTs.length) return;
    const allAssetIds = filteredItems.map(nft => nft.asset_id);
    setSelectedNFTs(allAssetIds);
  };

  // Tüm NFT'lerin seçimini kaldır
  const handleUnselectAll = () => {
    if (selectedNFTs.length === 0) return;
    setSelectedNFTs([]);
  };

  const handleSelectNFT = (assetId) => {
    if (selectedNFTs.includes(assetId)) {
      setSelectedNFTs(selectedNFTs.filter(id => id !== assetId));
    } else {
      setSelectedNFTs([...selectedNFTs, assetId]);
    }
  };

  const handleStake = () => {
    console.log('Staked NFTs:', selectedNFTs);
    handleSend(selectedNFTs); // Prop olarak gelen handleStake fonksiyonunu çağırıyoruz
    onClose();
  };

  const handleFilterChange = (collection, schema, template_id) => {
    let filtered = nftData;
  
    if (collection) {
      filtered = filtered.filter(item =>
        item.collection.toLowerCase().includes(collection.toLowerCase())
      );
    }
  
    if (schema) {
      filtered = filtered.filter(item =>
        item.schema.toLowerCase().includes(schema.toLowerCase())
      );
    }
  
    // templateId var mı diye kontrol ediyoruz ve sadece tanımlı olduğunda filtre uyguluyoruz
    if (template_id) {
      filtered = filtered.filter(item =>
        item.template_id && item.template_id.toString() === template_id.toString()
      );
    }
  
    setFilteredItems(filtered);
  };

  const handleClearFilters = () => {
    setCollectionFilter('');
    setSchemaFilter('');
    setTemplateIdFilter('');
    setFilteredItems(nftData);
  };

  // Resim veya video yüklenme durumu değiştiğinde güncelleme
  const handleMediaLoad = (assetId) => {
    setImageLoading(prevState => ({
      ...prevState,
      [assetId]: false,  // Medya başarıyla yüklendi
    }));
  };

  // Medya yüklenirken veya hata oluştuğunda
  const handleMediaError = (assetId) => {
    setImageLoading(prevState => ({
      ...prevState,
      [assetId]: true,  // Medya yüklenemedi
    }));
  };

  if (!show) return null;

  return (
    <div className="stakemodal-overlay">
      <div className="stakemodal-container">
        <div className="stakemodal-header">
          <h3>Unstaked Inventory</h3>
          <i className="fas fa-times close-icon" onClick={onClose}></i>
        </div>

        <div className="stakemodal-actions">
          <div className="stakemodal-filter">
            <input
              type="text"
              placeholder="Collection Filter"
              value={collectionFilter}
              onChange={(e) => {
                setCollectionFilter(e.target.value);
                handleFilterChange(e.target.value, schemaFilter, templateIdFilter);
              }}
            />
            <input
              type="text"
              placeholder="Schema Filter"
              value={schemaFilter}
              onChange={(e) => {
                setSchemaFilter(e.target.value);
                handleFilterChange(collectionFilter, e.target.value, templateIdFilter);
              }}
            />
            <input
              type="number" // Type changed to number for numeric filter
              placeholder="Template ID"
              value={templateIdFilter}
              onChange={(e) => {
                setTemplateIdFilter(e.target.value);
                handleFilterChange(collectionFilter, schemaFilter, e.target.value);
              }}
            />
            {(collectionFilter || schemaFilter || templateIdFilter) && (
              <button onClick={handleClearFilters} className="clear-button">
                Clear
              </button>
            )}
          </div>
          
          <div className="button-group">
            <button 
              className="select-button" 
              onClick={handleSelectAll}
              disabled={filteredItems.length === selectedNFTs.length} // Tüm NFT'ler seçiliyse butonu deaktif yap
            >
              Select All
            </button>
            <button 
              className="unselect-button" 
              onClick={handleUnselectAll}
              disabled={selectedNFTs.length === 0} // Hiçbir NFT seçili değilse butonu deaktif yap
            >
              Unselect All
            </button>
            <button 
              className="stake-button" 
              onClick={handleStake} 
              disabled={selectedNFTs.length === 0}
            >
              Send NFTs
            </button>
          </div>
        </div>

        {loading ? (
          <div className="loading-indicator">Loading NFTs...</div>
        ) : (
          <div className="stakemodal-body">
            {filteredItems.length > 0 ? (
              filteredItems.map((nft) => (
                <div 
                  key={nft.asset_id} 
                  className={`stakemodal-nft-card ${selectedNFTs.includes(nft.asset_id) ? 'selected' : ''}`}
                  onClick={() => handleSelectNFT(nft.asset_id)}
                >
                  <div className="nft-asset-id">#{nft.asset_id}</div>
                  {imageLoading[nft.asset_id] ? (
                    <div className="loading-indicator">Loading Media...</div>
                  ) : nft.mediaType === 'image' ? (
                    <img 
                      src={nft.media} 
                      alt={nft.name_} 
                      className="stake-media" 
                      onLoad={() => handleMediaLoad(nft.asset_id)} 
                      onError={() => handleMediaError(nft.asset_id)} 
                    />
                  ) : (
                    <video 
                      src={nft.media} 
                      className="stake-media"                      
                      autoPlay
                      muted
                      loop
                      onLoad={() => handleMediaLoad(nft.asset_id)} 
                      onError={() => handleMediaError(nft.asset_id)} 
                    />
                  )}
                  <div className="nft-info">
                    <h5>{nft.name_}</h5>
                    <p><strong>Collection:</strong> {nft.collection}</p>
                    <p><strong>Schema:</strong> {nft.schema}</p>
                    <p><strong>Template ID:</strong> {nft.template_id}</p>
                  </div>
                  <button 
                    className={`nft-select-button ${selectedNFTs.includes(nft.asset_id) ? 'unselect' : 'select'}`} 
                    onClick={(e) => {
                      e.stopPropagation(); 
                      handleSelectNFT(nft.asset_id);
                    }}
                  >
                    {selectedNFTs.includes(nft.asset_id) ? 'Unselect' : 'Select'}
                  </button>
                </div>
              ))
            ) : (
              <p>No NFTs available</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StakeModal;
