import React, { useState, useEffect } from 'react';
import './Wallet.css'; // CSS dosyasını import ediyoruz
import { fetchTokens, fetchUserCompToken } from '../contractService'; // Token listesini almak için
import WithdrawModal from '../WithdrawModal';
import DepositModal from '../DepositModal';

const Wallet = ({ wallet, selectedWallet, userData, withdrawAct, refresh, depositAct, completeTknAct}) => {
  const [balances, setBalances] = useState([]); // Bakiyeleri liste şeklinde tutuyoruz
  const [tokens, setTokens] = useState([]); // Token listesini tutuyoruz
  const [owner, setOwner] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false); // Modal kontrolü için state
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [selectedWithdraw, setSelectedWithdraw] = useState({});
  const [tempItems, setTempItems] = useState([]);

  // Token listesini ve kullanıcı bakiyesini çekiyoruz
  useEffect(() => {
    const fetchData = async () => {
      const tokenList = await fetchTokens();
      setTokens(tokenList);
    };

    fetchData();    
    if (userData && userData.balance) {
      const formattedBalances = userData.balance.map(curr => {
        const [amount, tokenSymbol] = curr.split(' ');
        const decimalPlaces = amount.includes('.') ? amount.split('.')[1].length : 0;
        const tokenConfig = tokens.find(t => {
          const [precision, symbol] = t.token_symbol.split(',');
          return parseInt(precision) === decimalPlaces && symbol === tokenSymbol;
        });
        return {
          amount: parseFloat(amount),
          tokenSymbol,
          contract: tokenConfig ? tokenConfig.contract : 'Unknown',
          precision: tokenConfig ? parseInt(tokenConfig.token_symbol.split(',')[0]) : decimalPlaces
        };
      });
      setBalances(formattedBalances);
    }

    if (wallet && wallet === selectedWallet) {
      setOwner(true);  
    } else {
      setOwner(false);
    }

  }, [wallet, selectedWallet, userData, tokens, refresh]);

  useEffect(() => {
    if (owner) {
      const getUserTempTokens = async () => {
        try {
          const temps = await fetchUserCompToken(selectedWallet);
          setTempItems(temps);
        } catch (error) {
          console.log(error);
        }
      };
      getUserTempTokens();
    }
  }, [owner, selectedWallet, refresh]);

  const handleDeposit = () => {
    setShowDepositModal(true);
  };

  const handleDepositConfirm = async (amount,contract) => {
    
    await depositAct(amount,contract);
    setShowDepositModal(false); 
  };

  const handleWithdrawConfirm = async (withdrawAmount) => {
    // Bu kısımda çekim işlemini gerçekleştireceğiz
    console.log("withdrawAmount");
    console.log(withdrawAmount);
    await withdrawAct(withdrawAmount);
    setShowWithdrawModal(false); 
  };

  const handleWithdraw = (amount, tokenSymbol, contract, precision) => {
    setSelectedWithdraw({ amount, tokenSymbol, contract, precision}); // Seçilen token bilgilerini kaydediyoruz
    setShowWithdrawModal(true); // Modalı açıyoruz
  };

  // Bakiyeleri kartlarda göstermek
  const renderBalance = () => {
    return balances.map(({ amount, tokenSymbol, contract, precision }, index) => (
      <div className="balance-card" key={index}>
        <div className="token-amount">{amount.toFixed(precision)}</div>
        <div className="token-name">{tokenSymbol}</div>
        <div className="token-contract">
          <strong className="contract-info">Contract:</strong> {contract}
        </div>
        {owner && (
          <button 
            className="withdraw-button" 
            onClick={() => handleWithdraw(amount.toFixed(precision), tokenSymbol, contract, precision)}
          >
            Withdraw
          </button>
        )}
      </div>
    ));
  };

  const handleCompleteDeposit = () => {
    console.log('Deposit işlemi tamamlanıyor...');
    completeTknAct();
  };

  return (
    <div className="wallet">
      <h2>Wallet</h2>

      {owner && !userData && (
        <p>You need to be registered to perform any actions.</p>
      )}

      {owner && userData && tempItems.length > 0 && (
        <div className="deposit-warning">
          <p>You have {tempItems.length} incomplete deposit transaction(s).</p>
          <button onClick={handleCompleteDeposit} className="complete-deposit-button">
            Complete Deposit
          </button>
        </div>
      )}
      <div className="wallet-actions">
        {owner && userData &&(
          <>
            <button onClick={handleDeposit}>Deposit</button>
          </>
        )}
      </div>

      <div className="wallet-balance">
        <p><strong>Wallet:</strong> {selectedWallet}</p>
        <p><strong>Balance:</strong></p>
        <div className="balance-container">
          {balances.length > 0 ? renderBalance() : <p>No tokens available</p>}
        </div>
      </div>

      {/* Withdraw modalını ekliyoruz */}
      <WithdrawModal
        show={showWithdrawModal}
        onClose={() => setShowWithdrawModal(false)}
        amount={selectedWithdraw.amount}
        tokenSymbol={selectedWithdraw.tokenSymbol}
        contract={selectedWithdraw.contract}
        precision = {selectedWithdraw.precision}
        onWithdrawConfirm={handleWithdrawConfirm}
      />

      <DepositModal
        show={showDepositModal}
        onClose={() => setShowDepositModal(false)}
        wallet={wallet}
        handleDepositConfirm = {handleDepositConfirm}
        
      />  
    </div>
  );
};

export default Wallet;
