import React, { useState } from 'react';
import './Marketplace.css';

function Marketplace() {
  const [selectedCollection, setSelectedCollection] = useState("theopenspace");
  const [key, setKey] = useState(0);

  const collections = [
    { id: "theopenspace", name: "The Open Space" },
    // Buraya ileride yeni koleksiyonlar ekleyebilirsin
  ];

  const handleCollectionChange = (e) => {
    setSelectedCollection(e.target.value);
    setKey(prevKey => prevKey + 1); // Her değişiklikte bileşeni yeniden yüklemek için key değiştiriyoruz
  };

  return (
    <div className="marketplace-container mt-5">
      <div className="marketplace-header">
        {/*<h2 className="marketplace-title">NFT Marketplace</h2>*/} 
        <div className="collection-selection">
          {collections.map((collection) => (
            <label key={collection.id}>
              <input
                type="radio"
                value={collection.id}
                checked={selectedCollection === collection.id}
                onChange={handleCollectionChange}
              />
              {collection.name}
            </label>
          ))}
        </div>
      </div>
      {/*<p className="marketplace-description">Buy, sell, and trade NFTs within the game.</p>*/}
      <div className="marketplace-items">
        <neftyblocks-market key={key} collection={selectedCollection}></neftyblocks-market>
      </div>
    </div>
  );
}

export default Marketplace;
