import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginDropdown.css';
import defaultAvatar from '../../assets/images/default_avatar.png'; // Avatar resmini import ediyoruz

const PLAYFAB_TITLE_ID = process.env.REACT_APP_PLAYFAB_TITLE_ID;

function LoginDropdown({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [loginError, setLoginError] = useState('');
  const [registerError, setRegisterError] = useState(''); 

  useEffect(() => {
    const loadPlayFabSdk = () => {
      if (!window.PlayFab) {
        const script = document.createElement('script');
        script.src = "https://download.playfab.com/PlayFabClientApi.js";
        script.async = true;
        script.onload = () => {
          console.log("PlayFab SDK loaded");
        };
        document.body.appendChild(script);
      }
    };

    loadPlayFabSdk();

    const sessionTicket = localStorage.getItem('sessionTicket') || sessionStorage.getItem('sessionTicket');
    if (sessionTicket) {
      onLogin(sessionTicket);
      setIsLoggedIn(true);
      console.log("Session restored from storage.");
    }
  }, [onLogin]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setLoginError(''); // Menü kapandığında hata mesajını sil
        setRegisterError(''); // Menü kapandığında hata mesajını sil
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {  // Menü açıldığında hata mesajlarını temizle
      setLoginError('');
      setRegisterError('');
    }
  };

  const handleTabSwitch = (isRegistering) => {
    setIsRegistering(isRegistering);
    setLoginError('');  // Tablar arasında geçiş yaparken hata mesajlarını temizle
    setRegisterError('');
  };

  const handleLogin = () => {
    const PlayFab = window.PlayFab;

    if (PlayFab && typeof PlayFab.ClientApi !== 'undefined') {
        const loginRequest = {
            TitleId: PLAYFAB_TITLE_ID,
            Email: email,
            Password: password,
        };

        PlayFab.ClientApi.LoginWithEmailAddress(loginRequest, (result, error) => {
            if (result) {
                const storage = rememberMe ? localStorage : sessionStorage;
                storage.setItem('sessionTicket', result.data.SessionTicket);
                onLogin(result.data.SessionTicket);
                setIsLoggedIn(true);
                setLoginError(''); // Hata mesajını temizle
                console.log("Login successful");
            } else {
                setLoginError(error.errorMessage || 'Login failed. Please try again.'); // Hata mesajını ayarla
                console.log(error.errorMessage);
            }
        });
    } else {
        console.log("PlayFabClientApi is not defined.");
    }
  };

  const handleRegister = () => {
    if (password !== confirmPassword) {
        setRegisterError('Passwords do not match!'); // Şifreler eşleşmezse hata mesajı
        return;
    }

    const PlayFab = window.PlayFab;

    if (PlayFab && typeof PlayFab.ClientApi !== 'undefined') {
        const registerRequest = {
            TitleId: PLAYFAB_TITLE_ID,
            Email: email,
            Password: password,
            Username: username,
        };

        PlayFab.ClientApi.RegisterPlayFabUser(registerRequest, (result, error) => {
            if (result) {
                setRegisterError(''); // Hata mesajını temizle
                handleLogin();
            } else {
                setRegisterError(error.errorMessage || 'Registration failed. Please try again.'); // Hata mesajını ayarla
                console.log(error.errorMessage);
            }
        });
    } else {
        console.log("PlayFabClientApi is not defined.");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('sessionTicket');
    sessionStorage.removeItem('sessionTicket');
    setIsLoggedIn(false);
    setIsOpen(false);
    console.log("Logout successful");
  };

  const goToMyAccount = () => {
    navigate('/myaccount');
    setIsOpen(false);
  };

  const handleForgotPassword = () => {
    const PlayFab = window.PlayFab;

    if (PlayFab && typeof PlayFab.ClientApi !== 'undefined') {
      const resetRequest = {
        TitleId: PLAYFAB_TITLE_ID,
        Email: email,
      };

      PlayFab.ClientApi.SendAccountRecoveryEmail(resetRequest, (result, error) => {
        if (result) {
          console.log('Password reset email sent.');
        } else {
          console.log(error.errorMessage);
        }
      });
    } else {
      console.log("PlayFabClientApi is not defined.");
    }
  };

  return (
    <div className="login-dropdown" ref={dropdownRef}>
      {isLoggedIn ? (
        <div>
          <button className="dropdown-toggle profile-info" type="button" onClick={toggleDropdown}>
            <img src={defaultAvatar} alt="Avatar" /> {/* Avatarı ekliyoruz */}
            <span>Account</span>
          </button>
          <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
            <button className="my-account-button" onClick={goToMyAccount}>
              My Account
            </button>
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      ) : (
        <div>
          <button className="dropdown-toggle" type="button" onClick={toggleDropdown}>
            Sign In / Sign Up
          </button>
          <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
            <div className="tabs">
              <button
                className={`tab-button ${!isRegistering ? 'active' : ''}`}
                onClick={() => handleTabSwitch(false)}
              >
                Sign In
              </button>
              <button
                className={`tab-button ${isRegistering ? 'active' : ''}`}
                onClick={() => handleTabSwitch(true)}
              >
                Sign Up
              </button>
            </div>
            {!isRegistering ? (
              <div className="tab-content">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleLogin}>Sign In</button>
                <div className="remember-forgot-container">
                  <div className="remember-me">
                    <input
                      type="checkbox"
                      id="rememberMeCheckbox"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label htmlFor="rememberMeCheckbox">Remember Me</label>
                  </div>
                  <button type="button" className="forgot-password" onClick={handleForgotPassword}>
                    Forgot Password
                  </button>
                </div>
                {loginError && <div className="error-message">{loginError}</div>} {/* Hata mesajını ekliyoruz */}
              </div>
            ) : (
              <div className="tab-content">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button onClick={handleRegister}>Sign Up</button>
                {registerError && <div className="error-message">{registerError}</div>} {/* Hata mesajını ekliyoruz */}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginDropdown;
