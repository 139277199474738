import React, { useState, useEffect } from 'react';
import './BidModal.css'; // Modal için CSS
import { getUserBeforeBids } from './contractService';

const BidModal = ({ show, onClose, wallet, auctionData, handleConfirmBid, userData }) => {
  const [bidAmount, setBidAmount] = useState(''); // Kullanıcının girdiği bid miktarı
  const [minBid, setMinBid] = useState(0); // Minimum bid miktarı
  const [adjustedMinBid, setAdjustedMinBid] = useState(0); // Previous Bid sonrası minimum bid
  const [nextMinBid, setNextMinBid] = useState(0); // Bir sonraki genel minimum bid
  const [tokenSymbol, setTokenSymbol] = useState(''); // Auction'dan gelen token sembolü
  const [tokenPrecision, setTokenPrecision] = useState(0); // Auction'dan gelen token hassasiyeti
  const [userBalance, setUserBalance] = useState(null); // Kullanıcı bakiyesi
  const [previousBid, setPreviousBid] = useState(null); // Daha önceki teklif
  const [buttonText, setButtonText] = useState('Confirm Bid'); // Butonun üzerindeki yazı

  // Auction verilerini kullanarak minimum bid hesaplama ve token bilgilerini ayarlama
  useEffect(() => {
    if (show && auctionData) {
      const [priceAmount, priceSymbol] = auctionData.price.split(' '); // Fiyatı ve token sembolünü ayır
      const precision = priceAmount.includes('.') ? priceAmount.split('.')[1].length : 0; // Hassasiyet hesapla

      const minBidAmount = parseFloat(priceAmount) * 1.11; // Minimum bid miktarı: mevcut fiyatın %11 fazlası
      setMinBid(minBidAmount);
      setTokenSymbol(priceSymbol); // Token sembolünü ayarla
      setTokenPrecision(precision); // Token hassasiyetini ayarla

      // Bir sonraki genel minimum bid'i hesapla
      const nextBidAmount = parseFloat(priceAmount) * 1.11; // Mevcut teklifin %11 fazlası
      setNextMinBid(nextBidAmount.toFixed(precision));

      // Kullanıcı bakiyesi hesaplama
      if (userData && wallet === userData.wallet) {
        const balanceToken = userData.balance.find(b => b.split(' ')[1] === priceSymbol); // Kullanıcının bakiyesindeki tokeni bul
        if (balanceToken) {
          setUserBalance(parseFloat(balanceToken.split(' ')[0])); // Bakiyeyi ayır ve miktarı al
        }
      }

      // Daha önce teklif verilip verilmediğini kontrol et
      const fetchPreviousBid = async () => {
        try {
          const userBid = await getUserBeforeBids(auctionData.id, wallet);
          if (userBid) {
            setPreviousBid(userBid); // Daha önceki teklifi kaydet
            const remainingAmount = minBidAmount - parseFloat(userBid.token.split(' ')[0]); // Mevcut minimum teklifi önceki tekliften düş
            const adjustedAmount = remainingAmount > 0 ? remainingAmount : 0; // Eğer kalan tutar pozitifse göster
            setAdjustedMinBid(adjustedAmount.toFixed(precision));
            setBidAmount(adjustedAmount.toFixed(precision)); // Kullanıcının bid alanına bu değeri yaz
          } else {
            setPreviousBid(null); // Eğer teklif yoksa null
            setBidAmount(minBidAmount.toFixed(precision)); // Bid amount minimum bid ile başlatılır
            setAdjustedMinBid(minBidAmount.toFixed(precision));
          }
        } catch (error) {
          console.error('Error fetching previous bid:', error);
        }
      };

      fetchPreviousBid();
    }
  }, [show, auctionData, userData, wallet]);

  // Miktarı hassasiyete göre formatlama işlemi
  const formatAmount = (amount, precision) => {
    if (!amount.includes('.')) {
      return amount + '.' + '0'.repeat(precision); // Nokta yoksa hassasiyet kadar sıfır ekle
    } else {
      const [integerPart, decimalPart] = amount.split('.');
      if (decimalPart.length < precision) {
        return integerPart + '.' + decimalPart + '0'.repeat(precision - decimalPart.length); // Hassasiyete göre sıfır ekle
      } else if (decimalPart.length > precision) {
        return integerPart + '.' + decimalPart.substring(0, precision); // Fazla karakterleri sil
      } else {
        return amount; // Zaten doğru formatta
      }
    }
  };

  // Bid onayı işlemi
  const handleConfirm = async () => {
    if (bidAmount) {
      const formattedAmount = formatAmount(bidAmount, tokenPrecision); // Miktarı formatla
      console.log(formattedAmount + " " + tokenSymbol + " , " + auctionData.id);
      await handleConfirmBid(formattedAmount + " " + tokenSymbol, auctionData.id)
    }
  };

  // Miktar input değişikliği
  const handleBidAmountChange = (e) => {
    let value = e.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/; // Yalnızca rakam ve tek bir nokta kabul edilir

    if (regex.test(value)) {
      const dotCount = (value.match(/\./g) || []).length;

      if (dotCount <= 1) {
        setBidAmount(value);

        // Kullanıcı bakiyesini kontrol et
        if (userBalance && parseFloat(value) > userBalance) {
          setButtonText('Insufficient Funds');
        } else if (parseFloat(value) < parseFloat(adjustedMinBid)) {
          setButtonText('Low Amount');
        } else {
          setButtonText('Confirm Bid');
        }
      }
    }
  };

  // Modal kapalıysa null dön
  if (!show || !auctionData) return null;

  return (
    <div className="bidmodal-overlay">
      <div className="bidmodal-container">
        <div className="bidmodal-header">
          <h3>Place Your Bid</h3>
          <i className="fas fa-times close-icon" onClick={onClose}></i>
        </div>
        <div className="bidmodal-body">
          <p><strong>Bid ID: </strong>{auctionData.id}</p> {/* Bid ID */}
          <p><strong>Last Bidder: </strong>{auctionData.last_bidder || 'No bids yet'}</p> {/* Last bidder */}
          <p><strong>Current Price: </strong>{auctionData.price}</p> {/* Auction fiyatı */}
          <p><strong>Next Minimum Bid: </strong>{nextMinBid} {tokenSymbol}</p> {/* Genel bir sonraki minimum bid */}
          {userData && wallet === userData.wallet && (
            <>
              <p><strong>Your Balance: </strong>{userBalance ? `${userBalance} ${tokenSymbol}` : 'No balance for this token'}</p>
              {previousBid && (
                <p><strong>Previous Bid: </strong>{previousBid.token}</p>
              )}
            </>
          )}
          <p><strong>Need Min Amount: </strong>{adjustedMinBid} {tokenSymbol}</p>
          <input
            type="text"
            id="bid-amount"
            value={bidAmount}
            onChange={handleBidAmountChange}
            placeholder={`Min bid: ${adjustedMinBid}`}
          />
        </div>
        <div className="bidmodal-footer">
          <button
            className="confirm-button"
            onClick={handleConfirm}
            disabled={!bidAmount || parseFloat(bidAmount) < parseFloat(adjustedMinBid) || parseFloat(bidAmount) > userBalance}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BidModal;
