// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.features-section {
    background-color: #1c1e22;
    color: white;
    padding: 60px 20px;
  }
  
  .features-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .feature-item {
    text-align: center;
    padding: 20px;
    background-color: #2a2d34;
    border-radius: 10px;
    transition: background-color 0.3s ease;
  }
  
  .feature-item i {
    font-size: 2em;
    color: #8df0ff;
    margin-bottom: 10px;
  }
  
  .feature-item:hover {
    background-color: #343a40;
  }
  .features-section h2 {
    font-size: 2.5em; 
    color: #4be7ff;
    margin-bottom: 50px;
    text-transform: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Features/Features.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,2DAA2D;IAC3D,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,sCAAsC;EACxC;;EAEA;IACE,cAAc;IACd,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,oBAAoB;EACtB","sourcesContent":[".features-section {\n    background-color: #1c1e22;\n    color: white;\n    padding: 60px 20px;\n  }\n  \n  .features-list {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n    gap: 20px;\n  }\n  \n  .feature-item {\n    text-align: center;\n    padding: 20px;\n    background-color: #2a2d34;\n    border-radius: 10px;\n    transition: background-color 0.3s ease;\n  }\n  \n  .feature-item i {\n    font-size: 2em;\n    color: #8df0ff;\n    margin-bottom: 10px;\n  }\n  \n  .feature-item:hover {\n    background-color: #343a40;\n  }\n  .features-section h2 {\n    font-size: 2.5em; \n    color: #4be7ff;\n    margin-bottom: 50px;\n    text-transform: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
