import React from 'react';
import './NotFound.css';
import { FaExclamationTriangle } from 'react-icons/fa'; // Font Awesome'dan bir ikon kullanıyoruz

function NotFound() {
  return (
    <div className="notfound-container text-center">
      <div className="notfound-icon">
        <FaExclamationTriangle size={80} /> {/* Uyarı sembolü */}
      </div>
      <h1 className="notfound-title">Oops... It seems something went wrong.</h1>
      <p className="notfound-message">404 - Page Not Found</p>
      <a href="/" className="notfound-btn"> Home</a>
    </div>
  );
}

export default NotFound;
